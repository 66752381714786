import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
/**
 *
 * @param email
 * @param type
 *
 */

const getReport = async (email: string, type: string) => {
  const url = `${Config.baseUrl}/report/report-types?email=${email}&type=${type}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getReport(email, type)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */

const fetchSearchData = async (email: string, data: any) => {
  const url = `${Config.baseUrl}/report/fund-reports?email=${email}&account_no=${data.account_no}&date_type=${data.date_type}&folio_no=${data.folio_no}&from_date=${data.from_date}&fund_code=${data.fund_code}&to_date=${data.to_date}&txn_id=${data.txn_id}&txn_type=${data.txn_type}&branch_code=${data.branch_code}&bank_code=${data.bank_code}&security_type=${data.security_type}&to_bank_code=${data.to_bank_code}&issue_date=${data.issue_date}&maturity_date=${data.maturity_date}&settlement_date=${data.settlement_date}&payment_type=${data.payment_type}&status=${data.status}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await fetchSearchData(email,data)
    }else {
      throw err;
      // return result
    }
}else {
      throw err
}
}
};

/**
 *
 * @param email
 *
 */

const getTransactionReportSearchData = async (email: string, data: any) => {
  const url = `${Config.baseUrl}/report/transaction-reports?email=${email}&from_date=${data.from_date}&to_date=${data.to_date}&txn_type=${data.txn_type}&status=${data.status}&amc_code=${data.amc_code}&fund_code=${data.fund_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getTransactionReportSearchData(email,data)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 *
 */

const getUnitHolderReportSearchData = async (email: string, data: any) => {
  const url = `${Config.baseUrl}/report/unitholder-reports?email=${email}&fund_code=${data.fund_code}&folio_no=${data.folio_no}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getUnitHolderReportSearchData(email,data)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getDailyNavReport = async (email: string, amc_code: string, fund:string, from_date:string, to_date:string) => {
  const url = `${Config.baseUrl}/fund/get-daily-nav-report?email=${email}&fund=${fund}&amc_code=${amc_code}&from_date=${from_date}&to_date=${to_date}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getDailyNavReport(email, amc_code, fund, from_date, to_date)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getUnitHolderStatement = async (email: string, folio_no: string, start_date:string, end_date:string) => {
  const url = `${Config.baseUrl}/transaction/txn-by-folio-and-date-range?email=${email}&folio_no=${folio_no}&start_date=${start_date}&end_date=${end_date}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getUnitHolderStatement(email, folio_no, start_date, end_date)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getBankStatement = async (email: string, account_no: string, start_date:string, end_date:string, type:string) => {
  const url = `${Config.baseUrl}/transaction/get-ledger-by-account-realized-date?email=${email}&account_no=${account_no}&start_date=${start_date}&end_date=${end_date}&type=${type}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getBankStatement(email,account_no, start_date, end_date, type)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getBankStatementOpeningBalance = async (email: string, account_no: string, start_date:string) => {
  const url = `${Config.baseUrl}/transaction/get-ledger-by-account-and-date?email=${email}&account_no=${account_no}&date=${start_date}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getBankStatementOpeningBalance(email,account_no, start_date)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }

}
};

const getdailyLedgerBalanceReport = async (email: string, account_no: string, start_date:string, end_date: string) => {
  const url = `${Config.baseUrl}/transaction/get-daily-ledger-by-account-and-date?email=${email}&account_no=${account_no}&start_date=${start_date}&end_date=${end_date}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getdailyLedgerBalanceReport(email,account_no, start_date, end_date)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getReceiptPaymentRegisterReport = async (email: string, fund_code: string, payment_type: string, realized: string, start_date:string, end_date: string) => {
  // const url = `${Config.baseUrl}/transaction/get-txn-by-type-and-date-range?email=${email}&fund_code=${fund_code}&payment_type=${payment_type}&start_date=${start_date}&end_date=${end_date}`;
  const url = `${Config.baseUrl}/transaction/ledger-by-realized-and-date-range?email=${email}&start_date=${start_date}&end_date=${end_date}&realized=${realized}&payment_type=${payment_type}&fund_code=${fund_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getReceiptPaymentRegisterReport(email, fund_code, payment_type, realized, start_date, end_date)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const addReconciliation = async (
  email: string,
  amc_code: string,
  fund_code: string,
  account_code: string,
  month: string,
  year: string,
  recon_date: string,
  bank_only_txn: string,
  system_only_txn: string,
  open_balance: string,
  close_balance: string,
  reconcile_balance: string,
  bank_closing: string,
  unconciled: string
) => {
  const url = `${Config.baseUrl}/reconc`;
  try{
  const result: any = await axios.post(
    url,
    {
      email,
      amc_code,
      fund_code,
      account_code,
      month,
      year,
      recon_date,
      bank_only_txn,
      system_only_txn,
      open_balance,
      close_balance,
      reconcile_balance,
      bank_closing,
      unconciled,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addReconciliation(email,
      amc_code,
      fund_code,
      account_code,
      month,
      year,
      recon_date,
      bank_only_txn,
      system_only_txn,
      open_balance,
      close_balance,
      reconcile_balance,
      bank_closing,
      unconciled)
    }else {
      throw err;
      // return result
    }
  }else {
    throw err
}
  }
};

/**
 *
 * @param email
 * @param type
 *
 */

const getReconData = async (
  email: string,
  dataObj: any,
  accountCode: string
) => {
  const url = `${Config.baseUrl}/report/reconcilation-report?email=${email}&amc_code=${dataObj.amc_code}&fund_code=${dataObj.fund_code}&account_no=${accountCode}&month=${dataObj.month}&year=${dataObj.year}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getReconData(email,dataObj,accountCode)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

// money market transaction detail report
const moneyMarketTransactionReport = async (
  email: string,
  amc_code: string,
  fund_code: string,
  security_type:string,
  type:string,
  date_from : string,
  date_to :string
) => {
  const url = `${Config.baseUrl}/report/mmsw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&security_type=${security_type}&type=${type}&date_from=${date_from}&date_to=${date_to}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await moneyMarketTransactionReport( email,amc_code,fund_code,security_type,type,date_from,date_to);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};
// Activity money market transaction detail report
const activityMoneyMarketTransactionReport = async (
  email: string,
  amc_code: string,
  fund_code: string,
  security_type:string,
  type:string,
  date_from : string,
  date_to :string
) => {
  const url = `${Config.baseUrl}/report/activity-mmsw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&security_type=${security_type}&type=${type}&date_from=${date_from}&date_to=${date_to}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await activityMoneyMarketTransactionReport( email,amc_code,fund_code,security_type,type,date_from,date_to);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

// equity investment transaction detail report
const equityInvestmentTransactionReport = async (
  email: string,
  amc_code: string,
  fund_code: string,
  type:string,
  date_from : string,
  date_to :string,
  settlement_from:string,
  settlement_to:string
) => {
  // const url = `${Config.baseUrl}/report/eisw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&type=${type}&date_from=${date_from}&date_to=${date_to}`;
  const url = `${Config.baseUrl}/report/eisw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&type=${type}&date_from=${date_from}&date_to=${date_to}&settlement_from=${date_from}&settlement_to=${date_to}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await equityInvestmentTransactionReport( email,amc_code,fund_code,type,date_from,date_to,settlement_from,settlement_to);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

// equity settlement report
const equitySettlementReport = async (
  email: string,
  amc_code: string,
  fund_code: string,
  date_from :string,
  date_to :string,
  date_type:string
) => {
  const url = `${Config.baseUrl}/report/get-txn-by-type?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&date_from=${date_from}&date_to=${date_to}&date_type=${date_type}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await equitySettlementReport( email,amc_code,fund_code,date_from,date_to,date_type);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};


const liabilityReceivableReport = async (
  email: string,
  account_no: string,
  start_date: string,
  end_date :string,
) => {
  const url = `${Config.baseUrl}/transaction/get-ledger-by-account-created-date?email=${email}&account_no=${account_no}&start_date=${start_date}&end_date=${end_date}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await liabilityReceivableReport( email,account_no,start_date,end_date);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};


const ipsHoldingStatementReport = async (email: string, amc_code: string, fund_code: string, security_type:string, date :string,) => {
  const url = `${Config.baseUrl}/report/moneymarket-ips-reports?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&date=${date}&security_type=${security_type}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await ipsHoldingStatementReport(email,amc_code,fund_code,security_type,date);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getBankSummary = async (email: string, amc_code:string,fund_code:string,bank_name: string) => {
  const url = `${Config.baseUrl}/account/account-by-bank-name?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&bank_name=${bank_name}`;
 try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getBankSummary(email,amc_code,fund_code,bank_name)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const equityUnsettledTransactionReport = async (
  email: string,
  settlement_date:string
) => {
  // const url = `${Config.baseUrl}/report/eisw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&type=${type}&date_from=${date_from}&date_to=${date_to}`;
  const url = `${Config.baseUrl}/report/auto-equity-unsettlement-txn?email=${email}&settlement_date=${settlement_date}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await equityUnsettledTransactionReport( email,settlement_date);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

const getUserActivity = async (
  email: string,
  date_from:string,
  date_to:string,
  search_email:string,
) => {
  // const url = `${Config.baseUrl}/report/eisw-report?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&type=${type}&date_from=${date_from}&date_to=${date_to}`;
  const url = `${Config.baseUrl}/report/user-summary-txn?email=${email}&date_from=${date_from}&date_to=${date_to}&search_email=${search_email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401 || err.response.status == 403 || err.response.statusCode == 403) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getUserActivity( email,date_from,date_to,search_email);
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};


export {
  getReport,
  fetchSearchData,
  getTransactionReportSearchData,
  getUnitHolderReportSearchData,
  getDailyNavReport,
  getUnitHolderStatement,
  getBankStatement,
  getBankStatementOpeningBalance,
  getdailyLedgerBalanceReport,
  getReceiptPaymentRegisterReport,
  addReconciliation,
  getReconData,
  moneyMarketTransactionReport,
  equityInvestmentTransactionReport,
  equitySettlementReport,
  liabilityReceivableReport,
  ipsHoldingStatementReport,
  getBankSummary,
  activityMoneyMarketTransactionReport,
  equityUnsettledTransactionReport,
  getUserActivity
};
