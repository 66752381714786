import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFunds } from '../../stores/services/funds.service';
import { getAmc } from '../../stores/services/amc.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from './report-header';
import { isDateValid, isNumber, sortAMCS } from '../../utils/customFunction';
import { Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from "react-google-charts";

import { Options } from 'tsparticles/Options/Classes/Options';

const FunctionalHighlights = () => {
    const pdfExportComponent = React.useRef<PDFExport>(null);
    const [loading, setLoading] = useState(false);
    const email = sessionStorage.getItem('email') || '';
    let date = new Date();
    const [Amc, setAmc] = useState('');
    const [fund, setFund] = useState('');

    useEffect(() => {
        const getAllAMC = async () => {
            try {
                const amcResponse = await getAmc(email, '', '', '');
                let sortAMCSByName = await sortAMCS(amcResponse.data.data);
                // setAllAmc(sortAMCSByName);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message)
                }
            }
        }
        //getAllAMC();
    }, [])

    //code for search data for Reports

    const searchData = async () => {
        try {

        } catch (err: any) {

        }
    }
    

    const data = [
        ["Task", "Rupees In Billion", { role: "style" }],
        ["Trustee Funds 130b", 130, "#4e67c8"],
        ["Custoday 60b", 60, "#A7EA52"],
        ["Reit Funds 30b", 30, "#5ECCF3"],

    ];


    // Calculate the total value from the data
    const totalValue: any = data.slice(1).reduce((sum: any, [, value]) => sum + value, 0);


    const options = {
        slices: [
            { color: "#4e67c8" }, // Red background color for the first data point
            { color: "#A7EA52" }, // Green background color for the second data point
            { color: "#5ECCF3" }  // Blue background color for the third data point
        ],
        pieHole: 0.5,
        is3D: false,
        width: 285,
        height: 230,
        tooltip: {
            isHtml: true,
            trigger: 'none'
        },
        chartArea: {
            left: 0,
            top: 0,
            right: 24,
            width: '100%',
            height: '80%'
        },
        pieSliceText: 'value', // Display actual data values
        pieSliceTextStyle: {
            color: '#000000', // Color of the text
            fontSize: 14,     // Size of the text
            bold: true        // Bold style
        },
        legend: {
            position: 'right',
            alignment: 'center',
            maxLines: 1,
            textStyle: {
                color: '#a5a5a5',
                fontSize: 10,
                bold: true,
            },


        },
        annotations: {
            textStyle: {
                color: '#ffffff',
                fontSize: 14,
                bold: true
            },
            stem: {
                color: 'transparent',
                length: '0px'
            },
            highContrast: false,
            total: {
                label: 'Total Value',
                color: 'red',
                fontSize: 16,
                showValue: true,
                position: 'center'
            }
        }
    };



    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Functional Highlights Report</h4>
                                    </div>
                                    <div className="card-body">
                                        <Row>
                                            <Col md="2">
                                                <div className="form-group  ">
                                                    <label>Select AMC</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setAmc(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                                                            getFunds(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" defaultChecked hidden>
                                                            Select AMC
                                                        </option>
                                                        {/* {renderAmcDropdown()} */}
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="form-group">
                                                    <label>Select Fund</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            //   setFund(e.nativeEvent.target == null ? '' : e.nativeEvent.target[e.target.selectedIndex].text)
                                                            //   getFundAccounts(e.target.value);
                                                        }}>
                                                        <option value="" defaultChecked hidden>
                                                            Select Fund
                                                        </option>
                                                        {/* {renderFundsDropDown()} */}
                                                    </select>
                                                </div>
                                            </Col>



                                            <Col md="2">
                                                <div className=" ">
                                                    {/* here Search Button */}
                                                    <button
                                                        className="btn btn-primary mt-4"
                                                        onClick={() => {
                                                            searchData();
                                                        }}
                                                        disabled={Boolean(loading)}
                                                    >
                                                        {loading ? (
                                                            <>
                                                                <span
                                                                    className="spinner-border login-txt spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                <span className="login-txt"> Loading...</span>
                                                            </>
                                                        ) : (
                                                            <span>Search</span>
                                                        )}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* End */}
                                    </div>
                                </div>

                                {/* here Search results for report Data */}
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div
                                            id="printDiv"
                                            className="p-4"
                                            style={{ background: 'white', color: 'black' }}
                                        >
                                            <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Functional-Highlights-Report-" + date + ".pdf"} ref={pdfExportComponent}>
                                                {/* {bankStatement.length > 0 ? ( */}
                                                <>
                                                    <Row >
                                                        <Col md='6'>
                                                            <span className="text-nowrap functionalHighlightsFont" style={{ fontSize: '20px', color: '#0e79ca', fontWeight: 'bold' }}>
                                                                Functional Highlights
                                                            </span>
                                                        </Col>
                                                        <Col md='2'>
                                                        </Col>
                                                        <Col md='4'>
                                                            <div className="text-center functionalHighlightsFont" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                Assets Under Administration <br />
                                                                <span className="text-center functionalHighlightsFont" style={{ fontSize: '10px', color: '#0e79ca', fontWeight: 'bold' }}>
                                                                   220 Rupees in Billion
                                                                </span>
                                                            </div>
                                                        </Col >
                                                    </Row>
                                                    <Row >
                                                        <Col md='8'>
                                                            <div
                                                                style={{
                                                                    overflowX: 'auto',
                                                                    overflowY: 'hidden',
                                                                }}
                                                            >
                                                                <table className="functionalHighlights functionalHighlightsFont" style={{ color: 'black' }}>
                                                                    <thead style={{ alignSelf: 'center' }}>
                                                                        <tr>
                                                                            <th style={{ width: '35%'}}><div className='text-nowrap' style={{ fontSize: '12px', color: '#073c65'}}>SEGMENTS</div></th>
                                                                            <th className='text-center'><div style={{ fontSize: '12px' }}>COMPANIES</div></th>
                                                                            <th className='text-center'><div style={{ fontSize: '12px' }}>SECURITIES</div></th>
                                                                            <th className='text-center'><div style={{ fontSize: '12px' }}>INVESTORS</div></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="invoice-table-body">
                                                                        <tr style={{ backgroundColor: '#EDFBDC' }}>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>Mutual Funds</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Trustee
                                                                                </span>
                                                                            </div>
                                                                            </td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>8</b> <br />
                                                                                <span >
                                                                                    AMCs
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>30</b> <br />
                                                                                <span>
                                                                                    Funds
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>33k</b> <br />
                                                                                <span>
                                                                                    Unitholders
                                                                                </span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>Special Accounts</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Custody
                                                                                </span></div></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>6</b> <br />
                                                                                <span>
                                                                                    AMCs
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>53</b> <br />
                                                                                <span>
                                                                                    SMAs
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>53</b> <br />
                                                                                <span>
                                                                                Investors
                                                                                </span></td>
                                                                        </tr>
                                                                        <tr style={{ backgroundColor: '#EDFBDC' }}>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>Private Equities</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Trustee
                                                                                </span></div></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>2</b> <br />
                                                                                <span>
                                                                                    AMCs
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>3</b> <br />
                                                                                <span>
                                                                                    Funds
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>3k</b> <br />
                                                                                <span>
                                                                                Unitholders
                                                                                </span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>REIT Schemes</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Trustee
                                                                                </span></div></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>3</b> <br />
                                                                                <span>
                                                                                    RMCs
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>3</b> <br />
                                                                                <span>
                                                                                    Schemes
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>5k</b> <br />
                                                                                <span>
                                                                                    Investors
                                                                                </span></td>
                                                                        </tr>
                                                                        <tr style={{ backgroundColor: '#EDFBDC' }}>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>Escrow</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Custody
                                                                                </span></div></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>3</b> <br />
                                                                                <span>
                                                                                Entities
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>5</b> <br />
                                                                                <span>
                                                                                    Agreements
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>10</b> <br />
                                                                                <span>
                                                                                    Parties
                                                                                </span></td>
                                                                        </tr>
                                                                        <tr style={{ borderBottom: '3px solid #B5ED6F' }}>
                                                                            <td className='text-nowrap'><div style={{ fontSize: '20px', color: '#073c65' }}><b>Registrar / TA</b> <br />
                                                                                <span style={{ fontSize: '16px'}}>
                                                                                    Corporate Share Registrar
                                                                                </span></div></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>21</b> <br />
                                                                                <span>
                                                                                    Companies
                                                                                </span></td>
                                                                            <td className='text-center'><b style={{ fontSize: '20px' }}>21</b> <br />
                                                                                <span>
                                                                                    Securities
                                                                                </span></td>
                                                                            <td style={{ borderBottom: '3px solid #B5ED6F' }} className='text-center'><b style={{ fontSize: '20px' }}>32k</b> <br />
                                                                                <span>
                                                                                    Shareholders
                                                                                </span></td>

                                                                        </tr>
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <td style={{fontSize:'20px', color: '#073c65'}}><b>Total</b></td>
                                                                            <td style={{fontSize:'20px'}} className='text-center'><b>43</b></td>
                                                                            <td style={{fontSize:'20px'}} className='text-center'><b>114</b></td>
                                                                            <td style={{fontSize:'20px'}} className='text-center'><b>73K</b></td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </Col >
                                                        <Col md='4'>
                                                            <Row>
                                                                <Col md='8' className="mt-2">
                                                                    <Chart
                                                                        chartType="PieChart"
                                                                        data={data}
                                                                        options={options}
                                                                        style={{ borderRadius: '1rem', paddingRight: '0px' }}
                                                                    />
                                                                </Col>
                                                                <Col md='2'>

                                                                </Col>
                                                            </Row>
                                                            <Col md='4' className='text-center text-nowrap mt-3'>
                                                            </Col>
                                                            <Row>
                                                                <Col md='5' className='text-center functionalHighlightsFont text-nowrap mt-3'>
                                                                    <div style={{ marginTop: '-80px' }}>
                                                                        Monthly Average <br />
                                                                        <span style={{ fontSize: '32px', color: '#0273C7' }}>+500</span><br />
                                                                        <span className='p-2'>Transactions</span>
                                                                    </div>
                                                                </Col>
                                                                <Col md='7' className='text-center functionalHighlightsFont mt-3'>
                                                                    <div className='text-nowrap' style={{ marginTop: '-80px' }}>
                                                                        Monthly Average <br />
                                                                        <span style={{ fontSize: '32px', color: '#0273C7' }}>+160</span><br />
                                                                        <span className='p-3'>Rupees</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md='5' className='text-center functionalHighlightsFont text-nowrap mt-2'>
                                                                    Yearly Average <br />
                                                                    <span style={{ fontSize: '32px', color: '#0273C7' }}>+3.7k</span><br />
                                                                    <span className='p-2'>Transactions</span>
                                                                </Col>
                                                                <Col md='7' className='text-center functionalHighlightsFont text-nowrap mt-2'>
                                                                    Yearly Average <br />
                                                                    <span style={{ fontSize: '32px', color: '#0273C7' }}>+1.7t</span><br />
                                                                    <span className='p-3'>Rupees</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                </>
                                            </PDFExport>
                                        </div>
                                        <button
                                            className="btn btn-primary mt-3"
                                            onClick={() => {
                                                // printReport();
                                                if (pdfExportComponent.current) {
                                                    pdfExportComponent.current.save();
                                                }
                                            }}
                                        >
                                            Print
                                        </button>


                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row >
            </div >
        </>
    );
};

export default FunctionalHighlights;
