import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col } from 'reactstrap';
import { HiDownload } from 'react-icons/hi';
import {
  getAllActiveDeactiveSecurities,
  updateSecurityStatus,
  uploadPsxFile,
  getAllPsx,
  searchSecurityAPI,
  getAllFundSecurity,
  updateFundSecuritySecurityStatus,
  updatePsx
} from '../../../stores/services/security.service';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { addSecurity } from '../../../stores/services/security.service';
import { ImCheckmark } from 'react-icons/im';
import { RiFilter2Fill } from 'react-icons/ri';
import moment from 'moment';
import * as fs from 'fs';
import { isNumber, numberWithCommas } from '../../../utils/customFunction';
import Pagination from './Pagination-equity-security/Pagination';
const createCsvWriter = require('csv-writer').createObjectCsvWriter;

const SecurityList = () => {
  const history = useHistory();
  const location: any = useLocation();
  let [changePass, setChangePAssword] = useState('1');
  let myparam: any = '1';
  const filepath = new Date().toISOString().split('T')[0] + '.csv';
  const email = sessionStorage.getItem('email') || '';
  const [data, setData] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  let [activePage, setActivePage] = useState('');
  const [isSearchActive, setSearchActive] = useState(false);
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  // Equity Pagination
  const PageSize = 30;
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    sessionStorage.removeItem('securityObj');
    sessionStorage.removeItem('fundSecurityObj');
    sessionStorage.removeItem('allFundObj');

    getSecurities();
  }, [activePage]);

  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      myparam = location.state.params;
      if (myparam === '2') {
        fetchAllPsxFiles();
      } else if (myparam === '3') {
        fetchFundSecurity()
      }
      setChangePAssword(myparam);
    }
  }, [])


  const getSecurities = async () => {
    setLoading(true);
    try {
      const response = await getAllActiveDeactiveSecurities(email, activePage, pageSize);
      if (response.data.data.length > 0) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        let responseData = response.data.data.map((item, index) => {
          if (item.status === undefined || item.status === '') {
            item.status = 'active';
          }
          return item;
        })
        setData(responseData);
      } else {
        setData([]);
        toast.error(response.data.message);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };
  const csvWriter = createCsvWriter({
    path: './app/containers/setup/security/' + 'data.csv',
    header: [
      { id: 'date', title: 'Date' },
      { id: 'symbol', title: 'Symbol' },
      { id: 'sector_code', title: 'Sector Code' },
      { id: 'company_name', title: 'Comp Name' },
      { id: 'open_rate', title: 'Open Rate' },
      { id: 'high_rate', title: 'High Rate' },
      { id: 'low_rate', title: 'Low Rate' },
      { id: 'close_rate', title: 'Close Rate' },
      { id: 'volume', title: 'Volume' },
      { id: 'ldcp', title: 'LDCP' },
    ],
  });
  // pagination
  // useEffect(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   // return data.slice(firstPageIndex, lastPageIndex);
  //   setPsxList(data.slice(firstPageIndex, lastPageIndex));
  // }, [currentPage]);


  //Equity Pagination 
  // const setPaginationAndHoldData = async (responseResult) => {
  //   setData(responseResult);
  //   setCurrentPage(1);
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   setPsxList(responseResult.slice(firstPageIndex, lastPageIndex));
  //   setLoading(false);
  // }

  const [psxList, setPsxList] = useState<any>([]);
  const fetchAllPsxFiles = async () => {
    setLoading(true);
    try {
      const response = await getAllPsx(email);
      // setPaginationAndHoldData(response.data.data);
      setPsxList(response.data.data.map(item=>{
        
        return{...item, status:item?.status == true || item?.status == false  ? item.status : true}
      }));
     

    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };

  const updatePSXSecurity = async (index:number,status:boolean) => {
    
    try {

      let temp=psxList
      temp[index].status = status

      const response = await updatePsx(email,JSON.stringify(temp));
      if(response.data.status == 200)
      {
        const res = await getAllPsx(email);
      setPsxList(res.data.data.map(item=>{
        return{...item, status:item?.status == true || item?.status == false ? item.status : true}
      }));
      toast.success(response.data.message);

      }
      
      
     

    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };
  const [allFundSecurities, setAllFundSecurities] = useState<any>([]);
  const fetchFundSecurity = async () => {
    setLoading(true);
    try {
      const response = await getAllFundSecurity(email);
      setAllFundSecurities(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  };

  const downLoadCsv = async (key: string, dataValues: any) => {
    //seting header for security files
    const psxHeader = {
      date: 'date',
      symbol: 'symbol',
      sector_code: 'sector_code',
      company_name: 'company_name',
      open_rate: 'open_rate',
      high_rate: 'high_rate',
      low_rate: 'low_rate',
      close_rate: 'close_rate',
      volume: 'volume',
      ldcp: 'ldcp',
    };
    var makePsxComplete = [...[psxHeader], ...dataValues]; // combine header and data values

    await csvWriter.writeRecords(makePsxComplete); //make Csv file inside over dir
    //here we are reading data from csv and making itx URl and add this url to herf to download csv
    await fs.readFile(
      `./app/containers/setup/security/data.csv`,
      (err, data) => {
        var blob = new Blob(['\ufeff', data]); // make blob for csv data
        var a = window.document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = key; // downloaded file name
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a); //remove a tag
      }
    );
    fs.unlinkSync(`./app/containers/setup/security/data.csv`);
  };
  //here we are rendering psx files list in equity tab
  const renderPsxData = () => {
    return psxList.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td>{items.company_name}</td>
          <td>{items.symbol}</td>
          <td>{items.sector_code}</td>
          <td>{moment(items.date.slice(0, 2) + "," + items.date.slice(2, items.date.length - 4) + "," + items.date.slice(items.date.length - 4, items.date.length)).format('YYYY-MM-DD')}</td>
          <td>{items.open_rate}</td>
          <td>{items.high_rate}</td>
          <td>{items.low_rate}</td>
          <td>{items.close_rate}</td>
          <td>{items.volume}</td>
          <td>{items.ldcp}</td>
          <td>{items.status === true ?"active" : "deactive"}</td>
          <td>{items.status !== true ?
              <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                onClick={() => {
                  updatePSXSecurity(index, true)
                  
                }}>
                <i className="tim-icons icon-simple-remove">
                </i></button>
              :
              <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                onClick={() => {
                  updatePSXSecurity(index, false)
                  
                }}>
                <i className="fas fa-check"></i>
              </button>}</td>
          
        </tr>
      );
    });
  };
  //here we are rendering Fund Securities list in equity tab
  const renderFundSecurityData = () => {
    // return
    return allFundSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td style={{ width: '45%' }}>{items.name}</td>
          <td >{items.code}</td>
          <td className='text-right'>{numberWithCommas(isNumber(parseFloat(items.price).toFixed(4)))}</td>
          <td className="captilize">{items.status}</td>
          <td>
            <ReactTooltip
              textColor="white"
              backgroundColor="black"
              effect="float"
            />
            <div className="multi-icons pointer">
              <div
                className="mr-3 pointer"
                onClick={() => {
                  setCurrentSecurityStatus(items.status);
                  setCode(items.code);
                  setStatusSetPopupFundSecurity(true);
                }}
              >
                {items.status !== 'active' ? (
                  <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                ) : (
                  <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                    <i className="fa fa-check"></i>
                  </button>
                )}
              </div>

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('fundSecurityObj', JSON.stringify(items));
                history.replace('/admin/view-fund-security');
              }}>
                <i className="fa fa-eye"></i>
              </button>

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('fundSecurityObj', JSON.stringify(items));
                // sessionStorage.setItem('allFundObj', JSON.stringify(allFundSecurities));
                history.replace('/admin/edit-fund-security');
              }}>
                <i className="fa fa-edit"></i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };

  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }


  const searchSecurityData = async () => {
    setLoading(true);
    try {
      const response = await searchSecurityAPI(email, searchValue);
      setData(response.data.data)
      setSearchActive(true)
      setPageSize('10')
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }
    setLoading(false);
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      fetchAllPsxFiles()
      setSearchActive(false)
      setPageSize('10')
    } else {
      setSearchValue(e.target.value);
    }
  }
  // update security
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentSecurityStatus, setCurrentSecurityStatus] = useState('');
  // const [currentFundSecurityStatus, setCurrentFundSecurityStatus] = useState('');
  const [code, setCode] = useState('');
  const [securityLoading, setSecurityLoading] = useState(false);

  const updateStatus = async () => {
    setSecurityLoading(true);
    let status = '';
    if (currentSecurityStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateSecurityStatus(email, code.replaceAll('SECURITY_', ''), status);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setLoading(true)
        setTimeout(() => {
          getSecurities();
        }, 3000);
        setStatusSetPopup(false);

      } else {
        toast.error(response.data.message);
      }

    } catch (error: any) {
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
    setSecurityLoading(false);
  };
  const updateFundSecurityStatus = async () => {
    setSecurityLoading(true);
    let status = '';
    if (currentSecurityStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {

      const response = await updateFundSecuritySecurityStatus(email, code, status);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setLoading(true)
        setTimeout(() => {
          fetchFundSecurity();
        }, 3000);
        setStatusSetPopupFundSecurity(false);

      } else {
        toast.error(response.data.message);
      }

    } catch (error: any) {
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
    setSecurityLoading(false);
  };

  const renderUpdateSecurityStatusPopup = () => {
    if (statusSetPopup === true) {
      return (
        <Modal
          className=""
          dialogClassName="modal60w"
           enforceFocus={false}
          show={true}
        >
          <div className="modal-header justify-content-center">
            <button aria-hidden="true" className="close" onClick={() => {
              setStatusSetPopup(false);
            }} data-dismiss="modal" type="button">
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h6 className="title title-up">Action</h6></div>
          <div className="modal-body">
            <div className="text-center">
              <Row>
                <div className="mx-auto">
                  {currentSecurityStatus === 'active' ? (
                    <img src="assets/ban.svg" alt="" width="70" />
                  ) : (
                    <img src="assets/check.svg" alt="" width="70" />
                  )}
                  <h1 className="pb-3">Are You Sure?</h1>
                  Are you sure you want to{' '}
                  {currentSecurityStatus === 'active'
                    ? 'Deactivate'
                    : 'Activate'}{' '}
                  this Security?
                </div>
              </Row>
              <Row>
                <Col>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(securityLoading)}
                      onClick={() => {
                        updateStatus();
                      }}
                    >
                      {securityLoading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        'Yes'
                      )}
                    </button>
                  </div>
                </Col>
                <Col>
                  <div className="">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setStatusSetPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      );
    } else {
      return '';
    }

  };
  const [statusSetPopupFundSecurity, setStatusSetPopupFundSecurity] = useState(false);
  const renderUpdateFundSecurityStatusPopup = () => {
    if (statusSetPopupFundSecurity === true) {
      return (
        <Modal
          className=""
          dialogClassName="modal60w"
          enforceFocus={false}
          show={true}
        >
          <div className="modal-header justify-content-center">
            <button aria-hidden="true" className="close" onClick={() => {
              setStatusSetPopupFundSecurity(false);
            }} data-dismiss="modal" type="button">
              <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h6 className="title title-up">Action</h6></div>
          <div className="modal-body">
            <div className="text-center">
              <Row>
                <div className="mx-auto">
                  {currentSecurityStatus === 'active' ? (
                    <img src="assets/ban.svg" alt="" width="70" />
                  ) : (
                    <img src="assets/check.svg" alt="" width="70" />
                  )}
                  <h1 className="pb-3">Are You Sure?</h1>
                  Are you sure you want to{' '}
                  {currentSecurityStatus === 'active'
                    ? 'Deactivate'
                    : 'Activate'}{' '}
                  this Security?
                </div>
              </Row>
              <Row>
                <Col>
                  <div className="">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(securityLoading)}
                      onClick={() => {
                        updateFundSecurityStatus();
                      }}
                    >
                      {securityLoading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        'Yes'
                      )}
                    </button>
                  </div>
                </Col>
                <Col>
                  <div className="">
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setStatusSetPopupFundSecurity(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      );
    } else {
      return '';
    }

  };



  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.security_type}</td>
          <td>{items.code.replace('SECURITY_', '')}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>{items.face_value}</td>
          <td>{items.price}</td>
          <td className="captilize">{items.status}</td>
          <td>
            <ReactTooltip
              textColor="white"
              backgroundColor="black"
              effect="float"
            />
            <div className="multi-icons pointer">
              <div
                className="mr-3 pointer"
                onClick={() => {
                  setCurrentSecurityStatus(items.status);
                  setCode(items.code);
                  setStatusSetPopup(true);
                }}
              >
                {items.status !== 'active' ? (
                  <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                ) : (
                  <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                    <i className="fa fa-check"></i>
                  </button>
                )}
              </div>

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('securityObj', JSON.stringify(items));
                history.replace('/admin/view-security');
              }}>
                <i className="fa fa-eye"></i>
              </button>

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {
                sessionStorage.setItem('securityObj', JSON.stringify(items));
                history.replace('/admin/edit-security');
              }}>
                <i className="fa fa-edit"></i>
              </button>

              <RiFilter2Fill className="mt-2 ml-1"
                data-tip="Filter it Uses"
                onClick={() => {
                  history.replace('/admin/security-transactions');
                  sessionStorage.setItem('securityName', items.name);
                }}
              />
            </div>
          </td>
        </tr>
      );
    });
  };
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const myRef1 = useRef<HTMLInputElement>(null);
  const uplaodFilePsx = async (fileDate: any) => {
    setFileUploadingFlag(true);
    await uploadPsxFile(sessionStorage.getItem('email') || '', fileDate)
      .then((response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          fetchAllPsxFiles();
          setFileName('');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err: any) => {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      });
    setFileUploadingFlag(false);
  };

  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const upload = (e: any) => {
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'lis') {
      setFile(file);
      setFileName(file.name);
      uplaodFilePsx(e?.target.files[0]);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };



  const renderContent = () => {
    const borderRadiusStyle = { borderRadius: 2 };
    if (changePass === '1') {
      return (
        <div className="">
          <div className="">
            <button
              className="btn btn-primary btn-sm ml-4 float-right"
              onClick={() => {
                history.replace('/admin/setup-security');
              }}
            >
              <i className="fa fa-eye mr-2"></i> Add New
            </button>
          </div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th>Security Type</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Issue Date</th>
                  <th>Maturity Date</th>
                  <th>Face Value</th>
                  <th >Price</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </table>
            {!isSearchActive &&
              <div
                className="row pr-3 pointer"
                style={{
                  float: 'right',
                }}
              >
                {/* PREVIOUS BUTTON */}
                {activePage !== paginationArr[0] ? (
                  <i
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={() => {
                      let pageArr = paginationArr;
                      let index = pageArr.findIndex((x) => x === activePage);
                      if (index !== -1) {
                        setActivePage(paginationArr[index - 1]);
                      }
                    }}
                  ></i>
                ) : (
                  ''
                )}
                {/* NEXT BUTTON */}
                {activePage !== paginationArr[paginationArr.length - 1] ? (
                  <i
                    className="fa ml-3 fa-arrow-right"
                    aria-hidden="true"
                    onClick={() => {
                      let pageArr = paginationArr;
                      let index = pageArr.findIndex((x) => x === activePage);
                      if (index !== -1) {
                        setActivePage(paginationArr[index + 1]);
                      }
                    }}
                  ></i>
                ) : (
                  ''
                )}
              </div>}
          </div>
        </div>
      );
    } else if (changePass === '2') {
      return (
        <div>
          <div className="input-holder">
            <div
              className="multi-input ml-auto"
              onClick={() => myRef1?.current?.click()}
            >
              <div className="form-group">
                <div className="form-control ml-5">
                  {fileName === '' ? 'Upload lis File' : fileName}
                </div>
                <input
                  type="file"
                  ref={myRef1}
                  style={{ display: 'none' }}
                  multiple={false}
                  onChange={(e) => {
                    upload(e);
                  }}
                />
                {fileError ? (
                  <p className="error-labels error-message2">{fileError}</p>
                ) : (
                  ''
                )}
                {/* <input type="file" id="amc_br" ref="amc_br_Uploader" style={{display: "none"}}/> */}
                {/* <div className="icon">	
                  <img src="assets/upload.svg" alt="" width="20" />	
                </div> */}
              </div>
            </div>
            {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-1x" ></i>}
          </div>

          {!Loading ? (
            <>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S No</th>
                      <th>Company Name</th>
                      <th>Symbol</th>
                      <th>Sector Code</th>
                      <th>Closing Date</th>
                      <th>Open Rate</th>
                      <th>High Rate</th>
                      <th>Low Rate</th>
                      <th>Close Rate</th>
                      <th>Volume</th>
                      <th>LDCP</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderPsxData()}</tbody>
                </table>
              </div>
              {/* <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          /> */}
            </>
          ) : (
            <div className="d-flex">
              <img src="assets/loader.svg" width="100" />
            </div>
          )}
        </div>
      );
    } else if (changePass === '3') {
      return (
        <div>
          <div className="">
            <button
              className="btn btn-primary btn-sm ml-4 float-right"
              onClick={() => {
                history.replace('/admin/setup-fund-security');
              }}
            >
              <i className="fa fa-eye mr-2"></i> Add New
            </button>
          </div>

          {!Loading ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th >Security Name</th>
                    <th >Code</th>
                    <th className='text-right'>Price</th>
                    <th>Status</th>
                    <th >action</th>
                  </tr>
                </thead>
                <tbody>{renderFundSecurityData()}</tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex">
              <img src="assets/loader.svg" width="100" />
            </div>
          )}
        </div>
      );
    }
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="d-flex align-items-center"></div>
                {!Loading ? (
                  <div className="">
                    <div className="card-header">
                      <h4 className="card-title">Security Listing</h4>
                    </div>
                    <div className="card-body">
                      <div className="w-100 d-flex mt-4">
                        <div
                          onClick={() => {
                            setChangePAssword('1');
                          }}
                          className={`card border shadow box-report user-header mr-2 w-50`}
                        >
                          {changePass === '1' && (
                            <ImCheckmark className="tick-noti tick-img" />
                          )}

                          <span className="card-title">Money Market</span>
                        </div>
                        <div
                          onClick={() => {
                            setChangePAssword('2');
                            fetchAllPsxFiles();
                          }}
                          className={`card border shadow box-report user-header w-50`}
                        >
                          {changePass === '2' && <ImCheckmark className="tick-noti tick-img" />}
                          <span className="card-title">Stocks</span>
                        </div>
                        <div
                          onClick={() => {
                            setChangePAssword('3');
                            fetchFundSecurity();
                          }}
                          className={`card border shadow box-report user-header w-50`}
                        >
                          {changePass === '3' && <ImCheckmark className="tick-noti tick-img" />}
                          <span className="card-title">Investment In Funds</span>
                        </div>
                      </div>
                      {renderContent()}

                    </div>
                    {/* <div className="line"></div> */}
                  </div>
                ) : (
                  <div className="d-flex justify-content-center my-5">
                    <i className="fa fa-spinner fa-spin fa-3x" ></i>
                  </div>
                )}

              </div>

            </Container>
          </Col>
        </Row>
        {renderUpdateSecurityStatusPopup()}
        {renderUpdateFundSecurityStatusPopup()}
      </div>

    </>
  );
};
export default SecurityList;
