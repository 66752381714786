import moment from "moment";
import DCClogo from "../../assets/img/DCC-Logo.png";
import { inWords } from "../../stores/services/template.service";
import { isNumber, numberWithCommas, } from "../../utils/customFunction";
import Config from "../../config";

export const mmPurchaseOfTDRLetter = (
  transaction: any,
  securities: any,
  transType: any,
  amtWords: any,
  transNetAmount: any,
  transGrossAmount: any
) => {
  let date = new Date();
  let dateFormat = moment(date).format("LL");
  let q = "qrcodemmPurhcaseOfTDR";
  let id = `${Config.q_r_url}/admin/verify-transaction/` + transaction.txn_id;
  let txnId = transaction.txn_id;
  let branchName = "";
  let branchCity = "";
  //     if(transaction.branch != undefined){
  //         branchName = transaction.branch.split(',')[0];
  //         branchCity = transaction.branch.split(',')[1];
  //         branchName = branchCity === undefined ? branchName+',' : branchName;
  //         branchCity = branchCity === undefined ? '' : branchCity+',';
  // }
  if (transaction.branch != undefined) {
    if (transaction.branch.includes("–")) {
      branchName = transaction.branch.split("–")[0];
      branchCity = transaction.branch.split("–")[1];
      branchName =
        branchCity === undefined
          ? branchName.trim() + ","
          : branchName.trim() + ",";
      branchCity = branchCity === undefined ? "" : branchCity.trim() + ".";
    } else {
      branchName = transaction.branch + ",";
    }
  }

  setTimeout(() => {
    if (id != undefined) {
      var element = document.getElementById(q)!;
      if (element != null) {
        element.innerHTML = "";
      }
      var qrcode = new QRCode(document.getElementById(q), {
        width: 50,
        height: 50,
      });
      qrcode.makeCode(id);
    }
  }, 500);

//   let securityTypeData = securities.map((u) => u.security_type).join("|");
let securityTypeData = "TDR"



  let totalAmt = 0;

  const getData = (items) => {
   
    let rows = "";
    for (let i = 0; i < items.length; i++) {
      totalAmt += parseFloat(isNumber(items[i].principal_amount) || "0");
      let maturityAmount = parseFloat((parseFloat(isNumber(items[i].principal_amount)) + (parseFloat(isNumber(items[i].principal_amount)) * (parseFloat(isNumber(items[i].yield))/365)* moment( items[i].maturity_date).diff(moment( items[i].issue_date),"days"))/100)?.toString() || "0").toFixed(2)
      rows += ` <tr>

      <td style="border: 1px solid #333;text-align:center;">${transaction.account_title}</td>
      <td style="border: 1px solid #333;text-align:center;">Term Deposit Receipt (TDR)</td>
            <td style="border: 1px solid #333;text-align:center;">${moment(
              items[i].issue_date
            ).format("LL")}</td>
            <td style="border: 1px solid #333;text-align:center;">${moment(
              items[i].maturity_date
            ).format("LL")}</td>
            
            <td style="border: 1px solid #333;text-align:center;">${parseFloat(isNumber(items[i].yield)).toFixed(2)}%</td>
            <td style="border: 1px solid #333;text-align:center;">${numberWithCommas(parseFloat(isNumber(items[i].principal_amount)).toFixed(2)) }</td>
            <td style="border: 1px solid #333;text-align:center;">${numberWithCommas(maturityAmount)}</td> 
        </tr>`;
    }
    
    return rows;
  };

  const getTotalValue = (items)=>{
    let total=0
    for (let i = 0; i < items.length; i++) {
      total += parseFloat(isNumber(items[i].principal_amount) || "0");
    }

    return total
  }


  return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
            <tr>
                <td>
                    <img src="${DCClogo}" alt="Trustee" height="70" />
                </td>
                <td valign="bottom">
                </td>
                <td valign="bottom">
                </td>
                <td align="right" valign="bottom">
                <div>
                <div style='text-align: right; margin-top:10px; height:60%; float: right;' id="${q}">
                </div>
                <span style='text-align: right; font-size:9px; margin-right:-53px; margin-top:60px; float: right;'>${txnId}</span>
                </div>
                </td>
            </tr>
            </table>
        </header>
        <hr />
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br />
            <div>The Cheif Manager <br /> ${
              transaction.bank
            } <br />  ${branchName}<br />${branchCity}</div>
            <br />
            <table style="width: 100%;"><tr>
            <td style="vertical-align:top;width:20px" ><b >Sub:</b></td>
            <td style="width:12px">&nbsp; &nbsp;</td>
            <td ><b >Investment In Term Deposit Receipt (TDR) Of PKR ${numberWithCommas(
              getTotalValue(securities.filter(item=>item.security_type == "TDR" && item.type == "Buy"))
              )}/-(Pak Rupees ${inWords(getTotalValue(securities.filter(item=>item.security_type == "TDR" && item.type == "Buy")))}) ${transaction.account_title}.</b> </td>
             </tr></table>
            
            <br />
            <div>Dear Sir/Madam,</div>    
            <br />
            <div>With reference to the captioned subject, you are requested to kindly receive fund though RTGS from ${transaction.bank}  amount of PKR=${numberWithCommas(
              getTotalValue(securities.filter(item=>item.security_type == "TDR"&& item.type == "Buy"))
              )}/-(Pak Rupees ${inWords(getTotalValue(securities.filter(item=>item.security_type == "TDR"&& item.type == "Buy")))}) dated ${moment(securities.map(item=>new Date(item.issue_date).getTime()).sort()?.[0]).format("LL")}   for issuance of TDR as follows: </div>
              <br/>
            <table style="width: 100%; border: 1px solid #333;">
          
            <tr>
                <th style="border: 1px solid #333; text-align:center;"> Beneficiary    </th>
                <th style="border: 1px solid #333;text-align:center;"> Transaction Type    </th>
                <th style="border: 1px solid #333;text-align:center;"> Value Date    </th>
                <th style="border: 1px solid #333;text-align:center;"> Maturity Date   </th>
                <th style="border: 1px solid #333;text-align:center;"> Mark-up Rate   </th> 
                <th style="border: 1px solid #333;text-align:center;"> Priniciple Amount    </th> 
                <th style="border: 1px solid #333;text-align:center;"> Maturity Amount   </th> 
            </tr>
 
                ${
                  transType === "debtmarketinvestment"
                    ? getData(securities.filter(item=>item.security_type == "TDR" && item.type == "Buy"))
                    : ""
                }

            </table>
            <br />
            <div>
            Furthermore, you are requested to make ensure delivery of original TDR as soon as possible.
            </div>
            <br />
            
            <div style="width: 100%;"> 

            <b>
            On maturity of the said TDR, you are requested to ensure that principal amount along with profit should be transfer through RTGS transfer in favor of 
            ${ transaction.bank} for further credit to our account title "${transaction.account_title} having IBAN ${ transaction.account_number} maintained with 
            ${transaction.bank}  ${
                branchCity
                  ? branchName + " " + branchCity.replace(".", "")
                  : transaction.branch
              }
               
              </b>.
            </div>
            <br />
            <div >
               Yours truly,
            </div> 
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;"><b>Authorized Signatory</b></div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;"><b>Authorized Signatory</b></div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `;
};
