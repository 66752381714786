import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { addFund, getFundType } from '../../../stores/services/funds.service';
import { getAmc } from '../../../stores/services/amc.service';
import { Container, Row, Col, FormGroup, Label, Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { BsPlusCircleFill } from 'react-icons/bs';
import {
  getAllSecurities,
  getAllPsx,
  getAllActiveFundSecurity,
} from '../../../stores/services/security.service';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import moment from 'moment';
import { isNumber, numberWithCommas } from '../../../utils/customFunction';
import { JsonToTable } from 'react-json-to-table';
import { json } from 'is';
import xls from "xlsx";
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router-dom';
import { inWords } from '../../../stores/services/template.service';
import converter from "number-to-words";

const csv = require('csvtojson/v2');


//const xlsx = require('xlsx-to-json');

const SetupFunds = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState('');
  const [code, setCode] = useState('');
  const [amcName, setAmcName] = useState('');
  const [fundType, setFundType] = useState('');
  let date = new Date();
  const [incorporationDate, setIncorporationDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [revocationDate, setRevocationDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [psxListing, setPsxListing] = useState('true');
  const [nav, setNav] = useState('');
  const [faceValue, setFaceValue] = useState('');

  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');
  const [clearMember_id, setCleariMemberID] = useState('');
  const [bats_id, setBatsID] = useState('');

  const email = sessionStorage.getItem('email') || '';
  //error getting hooks
  const [namelError, setNameError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [amcNameError, setAmcError] = useState('');
  const [fundTypeError, setFundTypeError] = useState('');
  const [incorporationDateError, setIncorporationDateError] = useState('');
  const [psxListingError, setPsxListingError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [navError, setNavError] = useState('');
  const [faceValError, setFaceValError] = useState('');
  // const [clearMemberError, setClearMemberError] = useState('');
  const [batsError, setBatsError] = useState('');
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [fundTpedata, setFundTpedata] = useState<any>([]);
  const [isCheckBoxSelected, setCheckBoxSelected] = useState(true);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [psxList, setPsxList] = useState<any>([]); // PSX securities Hook
  const [stockModal, setStockModal] = useState(false);
  const [termDepositModal, setTermDepositModal] = useState(false);
  const [feeSlabModal, setFeeSlabModal] = useState(false);
  const myRef1 = useRef<HTMLInputElement>(null);
  const myRef2 = useRef<HTMLInputElement>(null);
  const myRef3 = useRef<HTMLInputElement>(null);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [fileName2, setFileName2] = useState('');
  const [fileError2, setFileError2] = useState('');
  const [fileName3, setFileName3] = useState('');
  const [fileError3, setFileError3] = useState('');
  const [allFundSecurities, setAllFundSecurities] = useState<any>([]); // Fund as securities Hook

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const [feeSlabFrom, setFeeSlabFrom] = useState('');
  const [feeSlabTo, setFeeSlabTo] = useState('');
  const [feeRate, setFeeRate] = useState('')
  const [feeFixAmount, setFeeFixAmount] = useState('')
  const [feeSlabFromErr, setFeeSlabFromErr] = useState('');
  const [feeSlabToErr, setFeeSlabToErr] = useState('');
  const [feeRateErr, setFeeRateErr] = useState('')
  const [feeCheckBox,setFeeCheckBox] = useState("")
  const [feeFixAmountErr, setFeeFixAmountErr] = useState('')
  const [allfeeSlab, setAllFeeSlab] = useState<any>([])
  const [feeVariableRate,setFeeVariableRate] = useState("")
  const [feeComparingRate,setFeeComparingRate] = useState("")

  function myTrim(x) {
    return x.replace(/^\s+|\s+$/gm, '');
  }
  const fundCodeRegex = new RegExp('^[A-Z]{1,5}[A-Z]{1,5}$');

  useEffect(() => {
    const getFundSecurities = async () => {
      try {
        const response = await getAllActiveFundSecurity(email);
        let sortedData = response.data.data.sort(function (a, b) {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          if (x > y) { return 1; }
          if (x < y) { return -1; }
          return 0;
        });
        setAllFundSecurities(sortedData);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
    }
    getFundSecurities();
  }, [])


  const validate = () => {
    let nameError,
      codeError,
      amcErr,
      typeErr,
      inDateerr,
      psxErr,
      faceValErr,
      // clearMemberErr,
      // batsErr,
      navErr = '';
    fundName.trim() === '' ? (nameError = 'Required') : (nameError = '');
    code.trim() === ''
      ? (codeError = 'Required')
      : fundCodeRegex.test(code) !== true
        ? (codeError = 'Invalid')
        : (codeError = '');
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');
    fundType.trim() === '' ? (typeErr = 'Required') : (typeErr = '');
    // clearMember_id.trim() === '' ? (clearMemberErr = 'Required') : (clearMemberErr = '');
    // bats_id.trim() === '' ? (batsErr = 'Required') : (batsErr = '');
    // incorporationDate.trim() === '' ? inDateerr = "Required" :
    //   inDateerr = "";
    psxListing.trim() === '' ? (psxErr = 'Required') : (psxErr = '');
    nav.trim() === '' ? (navErr = 'Required') : (navErr = '');
    faceValue.trim() === '' ? (faceValErr = 'Required') : (faceValErr = '');
    if (
      nameError ||
      codeError ||
      amcErr ||
      typeErr ||
      inDateerr ||
      psxErr ||
      navErr ||
      // clearMemberErr ||
      // batsErr ||
      faceValErr
    ) {
      setNameError(nameError);
      setCodeError(codeError);
      setAmcError(amcErr);
      setNavError(navErr);
      setFaceValError(faceValErr);
      // setClearMemberError(clearMemberErr);
      // setBatsError(batsErr)
      setFundTypeError(typeErr);
      setIncorporationDateError(inDateerr);
      setPsxListingError(psxErr);
      return false;
    } else {
      return true;
    }
  };
  const AddFunds = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addFund(
          email,
          amcName,
          fundType,
          incorporationDate,
          myTrim(fundName),
          code,
          revocationDate,
          psxListing,
          nav,
          JSON.stringify(moneyMarket),
          JSON.stringify(stockMarket),
          JSON.stringify(fundSecurities),
          faceValue,
          JSON.stringify(termDeposit),
          cdc_participant_id,
          cdc_account_number,
          clearMember_id,
          bats_id,
          JSON.stringify(allfeeSlab)
        );
        if (response.data.status === 200) {
          setCheckBoxSelected(true);
          setFundName('');
          setCode('');
          stockMarket.length = 0;
          setStockMarket(stockMarket);
          moneyMarket.length = 0;
          setMoneyMarket(moneyMarket);
          termDeposit.length = 0;
          setTermDeposit(termDeposit);
          setNav('');
          setAmcName('');
          setFundType('');
          setIncorporationDate('');
          setRevocationDate('');
          setPsxListing('true');
          setFaceValue('');
          setCDCParticipantID('');
          setCDCAccountNumber('');
          setCleariMemberID('');
          setBatsID('');
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/funds`);
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      setLoading(false);
    }
  };

  const changeCodeValue = (value: any) => {
    setCode(value);
    if (value.trim() === '') {
      setCodeError('Required');
    } else {
      fundCodeRegex.test(value) !== true
        ? setCodeError('Invalid')
        : setCodeError('');
    }
  };

  React.useEffect(() => {
    const fetchAmc = async () => {
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const response = await getFundType(email);
        setFundTpedata(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const motResponse = await getAllSecurities(email);
        setSecurityTypeData(motResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }
      try {
        const response = await getAllPsx(email);
        let sortedData = response.data.data.sort(function (a, b) {
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if (x > y) { return 1; }
          if (x < y) { return -1; }
          return 0;
        });
        // setPsxList(response.data.data);
        setPsxList(sortedData);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }

      // get all banks
      try {
        const bankResponse = await getAllBanks(email);
        // temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(bankResponse.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message)
        }
      }

    };
    fetchAmc();
  }, []);

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setBranchData(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message)
      }
    }

  };

  const renderBankDropdown = () => {
    return bankNameData.map((item: any) => {
      return (
        <option key={item.bank_code} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_name} ({item.bank_code.replace('BANK_', '')})
        </option>
      );
    });
  };

  const renderBranchDropdown = () => {
    return branchData.map((item: any) => {
      return (
        <option key={item.branch_code} value={item.branch_name}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  const renderAmcDropdown = () => {
    return amcdata.map((item: any) => {
      return (
        <option key={item.amc_code} value={item.amc_code.replace('AMC_', '')}>
          {item.name} ({item.amc_code.replace('AMC_', '')})
        </option>
      );
    });
  };
  const addFeeSlab = () => {
    setDisableAddBtn(true);
    let valid = feeSlabValidate()
    if(feeComparingRate?.trim() && !feeCheckBox && valid) {
      setDisableAddBtn(false);
      return  toast.error("Kindly Select One Option")
    }
    if (valid) {
      let fee_slab = {
        "slab_from": parseFloat(isNumber(feeSlabFrom)).toFixed(4), 
        "slab_to":parseFloat(isNumber(feeSlabTo)).toFixed(4), 
        "rate": parseFloat(isNumber(feeRate)).toFixed(4), 
        "fix_amount":parseFloat(isNumber(feeFixAmount)).toFixed(4),
        variable_rate:parseFloat(isNumber(feeVariableRate)).toFixed(4),
        comparing_rate:parseFloat(isNumber(feeComparingRate)).toFixed(4),
        select_amount : feeComparingRate?.trim() ? feeCheckBox : ""
      }
      let DuplicateSlab = allfeeSlab?.filter(item=> (parseFloat(fee_slab.slab_from) >= parseFloat(item.slab_from) && parseFloat(fee_slab.slab_from) <= parseFloat(item.slab_to) || (parseFloat(fee_slab.slab_from) <= parseFloat(item.slab_from) && parseFloat(fee_slab.slab_to) >= parseFloat(item.slab_from)  && parseFloat(fee_slab.slab_to) <= parseFloat(item.slab_to))  )  )


      if (parseFloat(fee_slab.slab_to) < parseFloat(fee_slab.slab_from)) {
        toast.error("Slab To must be greater than Slab From")
        setDisableAddBtn(false);
      }else if(DuplicateSlab?.length > 0)
      {
        toast.error("Slab From Range Duplicate ");
        setDisableAddBtn(false);
      }
      else {
        let temp = allfeeSlab
        temp.push(fee_slab)

        setAllFeeSlab([...temp])

        setFeeSlabModal(false);
        setFeeSlabFrom('')
        setFeeSlabTo('')
        setFeeRate('')
        setFeeFixAmount('')
        setFeeComparingRate("")
        setFeeCheckBox("")
        setFeeVariableRate("")
        setModalView(false);
      }

    }




  }

  const feeSlabValidate = () => {
    let feeSlabFromError,
      feeSlabToError,
      feeRateError,
      feeFixAmountError = '';

    feeSlabFrom.trim() === '' ? (feeSlabFromError = 'Required') : (feeSlabFromError = '');
    feeSlabTo.trim() === '' ? (feeSlabToError = 'Required') : (feeSlabToError = '');
    if(feeRate.trim() === "" &&  feeFixAmount.trim() === "")
      {
        feeRateError = "Required"
        feeFixAmountError = "Required"
      }else{
        feeRateError = ""
        feeFixAmountError = ""

      }
    // feeRate.trim() === '' ? (feeRateError = 'Required') : (feeRateError = '');
    // feeFixAmount.trim() === '' ? (feeFixAmountError = 'Required') : (feeFixAmountError = '');

    if (feeFixAmountError ||
      feeRateError ||
      feeSlabFromError ||
      feeSlabToError) {
      setFeeSlabFromErr(feeSlabFromError)
      setFeeSlabToErr(feeSlabToError)
      setFeeFixAmountErr(feeFixAmountError)
      setFeeRateErr(feeRateError)
      setDisableAddBtn(false);
      return false

    } else {
      return true

    }

  }
  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code}>
          {item.name} ({item.code})
        </option>
      );
    });
  };

  const feeSlabinAmount =(value:any)=>{
    if(!value?.trim()) return ""
    if(value.includes("."))
    {
      let split = value.split(".")
      let num = ""
      if(split?.[0] && split?.[0]?.length > 15)
      {
        return ""
      }
      if(split?.[0] && split?.[0]?.length < 16)
      {
        num = converter.toWords(split?.[0])
      }
      if(split?.[1])
      {

        num  = num +" and "+ converter.toWords(split?.[1])
      }
      return num


    }else{
      if(value?.length > 15) return ""
      return converter.toWords(value)


    }


  }

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList?.filter((item:any)=> item.status == undefined || item?.status == true).map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.symbol}
          {/* {item.fund_name} ({item.symbol_code}) */}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);

  const renderFilterSecurityDataDropdown = (security_type: string) => {
    const check = securityTypeData.filter((item: any, index: string) => {
      return (item.security_type === security_type);
    });
    setSecurityData(check);
  };

  const renderSecuritiesDropDown = () => {
    return securityData.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_', '')}>
          {item.name.replace('SECURITY_', '')}
        </option>
      );
    });
  }

  const [modalView, setModalView] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [moneyMarketEntity, setMoneyMarketEntity] = useState<any>({
    code: '',
    total_amount: '0',
    // name: '',
  });
  // ADD TAX Entity inside tax Array
  const addMoneyMarket = async () => {
    setDisableAddBtn(true);
    let count = 0;

    for (let i in moneyMarketEntity) {
      if (moneyMarketEntity.otherCode === '') {
      } else {
        if (moneyMarketEntity[i] === '') {
          count += 1;
        }
      }
    }
    if (moneyMarketEntity['otherCode'] == 'Other') {
      count -= 1;
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      setDisableAddBtn(false);
    } else {
      if (moneyMarketEntity['otherCode'] === 'Other') {
        if (moneyMarketEntity['issue_date'] === undefined && moneyMarketEntity['maturity_date'] === undefined) {
          toast.error('Kindly select issue and maturity date')
          setDisableAddBtn(false);
        } else if (moneyMarketEntity['issue_date'] === undefined) {
          toast.error('Kindly select issue date')
          setDisableAddBtn(false);
        } else if (moneyMarketEntity['maturity_date'] === undefined) {
          toast.error('Kindly select maturity date')
          setDisableAddBtn(false);
        } else {
          let date = moneyMarketEntity['issue_date'].replace('-', '');
          let date2 = moneyMarketEntity['maturity_date'].replace('-', '');
          let symbol1 = moneyMarketEntity['security_type'] + '-' + date + '-' + date2;
          // setMoneyMarketEntity({
          //   ...moneyMarketEntity,
          //   code: symbol1,
          //   // name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
          //   name: symbol1
          // });
          const tempobj = { ...moneyMarketEntity, code: symbol1, name: symbol1 };
          setModalView(false);
          // push data inside array	
          moneyMarket.push(tempobj);
          setMoneyMarket(moneyMarket);
          // }	
          const filedEntirty = {
            code: '',
            otherCode: '',
            total_amount: '0',
            // name: '',	
          };
          setMoneyMarketEntity(filedEntirty);
          setDisableAddBtn(false);
        }
      } else {
        setModalView(false);
        // push data inside array	
        moneyMarket.push(moneyMarketEntity);
        setMoneyMarket(moneyMarket);
        // }	
        const filedEntirty = {
          code: '',
          total_amount: '0',
          // name: '',	
        };
        setMoneyMarketEntity(filedEntirty);
        setDisableAddBtn(false);
      }

    }
  };

  //render Fund table data
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>{numberWithCommas(items.coupon_rate || '')}</td>
          <td>{numberWithCommas(items.yield || '')}</td>
          <td>{numberWithCommas(items.mmface_value || '')}</td>
          <td>{numberWithCommas(items.face_value || '')}</td>
          <td>{numberWithCommas(items.price || '')}</td>
          <td>{numberWithCommas(items.units || '')}</td>
          <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td>
          <td>{items.tenor}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td   >
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...moneyMarket];
                array.splice(index, 1);
                setMoneyMarket(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  const renderFeeDataInTable = () => {

    return allfeeSlab.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{numberWithCommas(isNumber(items.slab_from))}</td>
          <td>{numberWithCommas(isNumber(items.slab_to))}</td>
          <td className='text-right'>{parseFloat(isNumber(items.rate)).toFixed(4)}</td>
          <td className='text-right'>{numberWithCommas(isNumber(items.fix_amount))}</td>
          <td className="text-right">{parseFloat(isNumber(items.variable_rate)).toFixed(4)}</td>
          <td className="text-right">{parseFloat(isNumber(items.comparing_rate)).toFixed(4)}</td>
          <td>{items.select_amount == "higher" ? "Select Higher" : items.select_amount == "lower" ? "Select Lower" : ""}</td>

          {/* <td>{items.calculatedAmount}</td> */}
          <td className='text-right' >
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...allfeeSlab];
                array.splice(index, 1);
                setAllFeeSlab(array);
              }}
            />
          </td>
        </tr>
      );
    });

  }

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  // Uploaders
  const uploadMoneyMarketPortfolio = async (e: any) => {
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === "xlsx" || type === 'csv') {
      setFile(file);
      setFileName(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          defval: "",
          dateNF: 'YYYY-MM-DD',
        });
        /* Update state */

        if (data) {
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              data[i]['code'] = data[i]['SYMBOL'];
              data[i]['security_type'] = data[i]['SECURITY TYPE'];
              data[i]['name'] = data[i]['Security Name'];
              data[i]['issue_date'] = data[i]['ISSUE DATE'];
              data[i]['maturity_date'] = data[i]['MATURITY DATE'];
              data[i]['coupon_rate'] = data[i]['COUPON RATE'];
              data[i]['yield'] = data[i]['YIELD'];
              data[i]['mmface_value'] = data[i][''];
              data[i]['face_value'] = data[i]['FACE VALUE'];
              data[i]['price'] = data[i]['PRICE'];
              data[i]['units'] = data[i]['UNITS'];
              data[i]['total_amount'] = data[i]['Amount'];
              if (data[i]['issue_date'] !== undefined) {
                data[i]['issue_date'] = data[i].issue_date === '' ? '' : moment(data[i].issue_date).format('YYYY-MM-DD');
              }
              if (data[i]['maturity_date'] !== undefined) {
                data[i]['maturity_date'] = data[i].maturity_date === '' ? '' : moment(data[i].maturity_date).format('YYYY-MM-DD');
              }

            }
          }
          setMoneyMarket(data);
          //setFileDataCount(data.length)
        }

      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);

    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const uploadStockMarketPortfolio = async (e: any) => {
    setFileError2('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === "txt") {
      setFile(file);
      setFileName2(file.name);
      processLineByLine(file);

    } else if (type === "xlsx" || type === 'csv') {
      setFile(file);
      setFileName2(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          defval: "",
          dateNF: 'YYYY-MM-DD',
        });
        /* Update state */
        if (data) {
          let data1 = data.map((item: any) => {
            return {
              code: item['Symbol'],
              close_rate: item['Average Rate'],
              unit: item['Quantity'],
              amount: item['Amount'],
              market: item['Market'],
              pledged: item['pledged']
            }
          })
          setStockMarket(data1);
          //setFileDataCount(data.length)
        }

      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);

    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  // Upload txt file for Stock Market Portfolio.
  async function processLineByLine(fileObj: any) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content: any = [];
    reader.onload = function (fileObj) {
      content = reader.result;
      content = content.split('\n');
      let headings = content[0].split(':')
      let line1 = headings[0].replace(/"/g, '').split(',');
      let code = line1[1];
      //  let date = headings[1].replace(/"/g, '');
      //  moment.defaultFormat = "DD/MM/YYYY";
      if (code === cdc_participant_id) {
        let column = [] as any;
        var data = [] as any;
        var i = 0;
        let TotalLine = content.length - 1;
        for (i = 0; i < TotalLine; i++) {
          content[i] = content[i].replaceAll('"', '').trim();
          //  let str = content[i].split(':');
          let str = content[i].split(',');
          if (i >= 1) {
            let obj: any;
            if (str[0].toString().includes('-')) {
              obj = {
                ['code']: str[0],
                ['market']: 'Future',
                ['unit']: str[4],
                ['close_rate']: '0',
                ['amount']: '0',
                ['pledged']: str[8],
              };
            } else {
              obj = {
                ['code']: str[0].toString().trim(),
                ['market']: 'Regular',
                ['unit']: str[4],
                ['close_rate']: '0',
                ['amount']: '0',
                ['pledged']: str[8],
              };
            }
            data.push(obj);
          }
        }
        setStockMarket(data);
      } else {
        setFileName2('');
        setFile('');
        toast.error("Invalid file.")
      }
    }
  }



  const uploadTermDepositPortfolio = async (e: any) => {
    setFileError2('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === "xlsx") {
      setFile(file);
      setFileName3(file.name);
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target?.result;
        const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data: any = xls.utils.sheet_to_json(ws, {
          header: 2,
          raw: false,
          defval: "",
          dateNF: 'YYYY-MM-DD',
        });
        /* Update state */

        if (data) {
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              if (data[i]['from_date'] !== undefined) {
                data[i]['from_date'] = data[i].from_Date === '' ? '' : moment(data[i].from_Date).format('YYYY-MM-DD');
              }
              if (data[i]['to_date'] !== undefined) {
                data[i]['to_date'] = data[i].to_date === '' ? '' : moment(data[i].to_date).format('YYYY-MM-DD');
              }

            }
          }
          setTermDeposit(data);
          //setFileDataCount(data.length)
        }

      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);

    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const [codeChange, setCodeChange] = useState('');

  // render Model for adding Fund
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setModalView(false);
              setFeeSlabModal(false);
            }}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false);
                  setFeeSlabModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> {feeSlabModal ? "Add Fee Slab" : stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
            </div>

            {!feeSlabModal ? <div className="modal-body">
              <Row>
                <Col>
                  <div className="form-group">
                    <div>Select Security / Placement</div>
                    <select
                      className='form-control'
                      value={moneyMarketEntity['security_type']}
                      onChange={(e) => {
                        renderFilterSecurityDataDropdown(e.target.value)
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          security_type: e.target.value
                        });

                      }}>
                      <option value="" defaultChecked hidden>
                        {' '}
                        Select Security / Placement</option>
                      <optgroup>Money Market</optgroup>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasury Bills</option>

                      <optgroup>Debt Market</optgroup>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      <option value='IJARA'>GoP Ijara Sukuks</option>
                      <option value='TFC'>Term Finance Certificates</option>
                      <option value='CP'>Commercial Papers</option>

                      <optgroup>Term Deposits</optgroup>
                      <option value='TDR'>Term Deposit Receipts</option>
                      <option value='CDR'>Call Deposit Receipts</option>
                      <option value='LOP'>Letter of Placement</option>
                    </select>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <div>Select Security</div>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />

                    <select
                      className="form-control"
                      value={moneyMarketEntity['otherCode'] === '' ? moneyMarketEntity['code'] : moneyMarketEntity['otherCode']}
                      onChange={(e) => {
                        // let value = securityTypeData.filter((item: any) => {
                        //   return item.code === e.target.value;
                        // });
                        // setMoneyMarketEntity({
                        //   ...moneyMarketEntity,
                        //   // code: e.target.value,
                        //   code:e.target.value,
                        //   name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                        // });
                        if (e.target.value === "Other") {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            // code: e.target.value,
                            otherCode: e.target.value,
                            otherName: e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                          });
                          // }
                        } else {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            // code: e.target.value,
                            code: e.target.value,
                            name: e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                          });
                        }
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Security
                      </option>
                      {/* {renderSecurityDataDropdown()} */}
                      {renderSecuritiesDropDown()}
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="form-group">
                    <div>Symbol</div>
                    <input
                      type="text"
                      className="form-control w-100"
                      value={moneyMarketEntity['code']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col> */}


              </Row>
              {/* <Row>
              <Col md="6">
                  <div className="form-group">
                      <div>Name</div>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={moneyMarketEntity['name']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>
              
              </Row> */}
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <div>Issue Date</div>
                    <input
                      type="date"
                      className="form-control w-100"
                      value={moneyMarketEntity['issue_date']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          issue_date: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <div>Maturity Date</div>
                    <input
                      type="date"
                      className="form-control w-100"
                      value={moneyMarketEntity['maturity_date']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          maturity_date: e.target.value,
                        });
                      }}
                    />
                  </div>

                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="form-group">
                    <div>Coupon Rate</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={parseFloat(moneyMarketEntity['coupon_rate']).toFixed(4)}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          coupon_rate: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>


                <Col md="6">
                  <div className="form-group">
                    <div>Yield</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={parseFloat(moneyMarketEntity['yield']).toFixed(4)}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          yield: e.value,
                        });
                      }}
                    />
                  </div>

                </Col>
              </Row>

              <Row>

                <Col md="6">
                  <div className="form-group">
                    <div>Money Market Face Value</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={parseFloat(moneyMarketEntity['mmface_value']).toFixed(4)}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          mmface_value: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <div>Total Face Value</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={parseFloat(moneyMarketEntity['face_value']).toFixed(4)}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          face_value: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>

              </Row>

              <Row>
                <Col md="6">
                  <div className="form-group">
                    <div>Price</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={moneyMarketEntity['price']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value, 4);
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          price: value,
                        });
                      }}
                    />
                  </div>

                </Col>
                <Col md="6">
                  <div className="form-group">
                    <div>Units</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={moneyMarketEntity['units']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value, 4);
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          units: value,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <div>Settlement Amount</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={moneyMarketEntity['total_amount']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value, 2);
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          total_amount: value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">

                  <div className="form-group">
                    <div>Tenor</div>
                    <input
                      type="number"
                      className="form-control w-100"
                      value={moneyMarketEntity['tenor']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          tenor: e.target.value,
                        });
                      }}
                    />
                  </div>

                </Col>
              </Row>

              <div className="row">
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      addMoneyMarket();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div> :
              <div className="modal-body">
                <Row >
                  <Col md="6">
                    <div className="form-group  ">
                      <label>Slab From</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeSlabFromErr ? 'required-border' : ''
                            }`}
                          value={feeSlabFrom}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeSlabFrom(
                                val + "." + decimalVal.substring(0, 2)
                              );
                            } else {
                              setFeeSlabFrom(e.target.value);
                            }
                            setFeeSlabFromErr('');
                          }}
                        />
                      </div>
                      <small  style={{lineHeight:1.3, width:'100%', display:"inline-block"}}>{feeSlabinAmount(feeSlabFrom)}</small>

                    </div>
                    <div className="form-group  ">
                      <label>Fixed Rate %</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeRateErr ? 'required-border' : ''
                            }`}
                          value={feeRate}
                          onChange={(e) => {
                            setFeeFixAmount("")
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeRate(
                                val + "." + decimalVal.substring(0, 4)
                              );
                            } else {
                              setFeeRate(e.target.value);
                            }

                            setFeeRateErr('');
                            setFeeFixAmountErr("")
                          }}
                        />
                      </div>
                      <small style={{lineHeight:1.3, width:'100%', display:"inline-block"}} ></small>
                    </div>
                    <div className="form-group  ">
                      <label>Variable Rate %</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 `}
                          value={feeVariableRate}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeVariableRate(
                                val + "." + decimalVal.substring(0, 4)
                              );
                            } else {
                              setFeeVariableRate(e.target.value);
                            }

                            
                          }}
                        />
                      </div>
                    </div>


                  </Col>

                  <Col md="6">

                    <div className="form-group  ">
                      <label>Slab To</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeSlabToErr ? 'required-border' : ''
                            }`}
                          value={feeSlabTo}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeSlabTo(
                                val + "." + decimalVal.substring(0, 2)
                              );
                            } else {
                              setFeeSlabTo(e.target.value);
                            }


                            setFeeSlabToErr('');
                          }}
                        />
                      </div>
                      <small  style={{lineHeight:1.3, width:'100%', display:"inline-block"}}>{feeSlabinAmount(feeSlabTo)}</small>

                    </div>

                    <div className="form-group  ">
                      <label>Fix Amount</label>
                      <div className="">
                        <input
                          type="number"
                          className={`form-control  w-100 ${feeFixAmountErr ? 'required-border' : ''
                            }`}
                          value={feeFixAmount}
                          onChange={(e) => {
                            setFeeRate("")
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeFixAmount(
                                val + "." + decimalVal.substring(0, 2)
                              );
                            } else {
                              setFeeFixAmount(e.target.value);
                            }
                            setFeeFixAmountErr('');
                            setFeeRateErr("")
                          }}
                        />
                      </div>
                      <small style={{lineHeight:1.3, width:'100%', display:"inline-block"}} >{feeFixAmount?.trim() === '' || feeFixAmount?.length > 15 ? '' : (inWords(feeFixAmount))}</small>

                    </div>
                    <div className="form-group  ">
                      <label>Comparing Rate %</label>
                      <div className="mb-1">
                        <input
                          type="number"
                          className={`form-control  w-100 `}
                          value={feeComparingRate}
                          onChange={(e) => {
                            if (e.target.value.includes(".")) {
                              let decimalVal = e.target.value.split(".")[1];
                              let val = e.target.value.split(".")[0];
                              setFeeComparingRate(
                                val + "." + decimalVal.substring(0, 4)
                              );
                            } else {
                              setFeeComparingRate(e.target.value);
                            }

                            
                          }}
                        />
                      </div>
                      {feeComparingRate && 
                      <div >
                      <form action="">
                                  <FormGroup
                                    check
                                    className="form-check-radio d-inline mr-3"
                                  >
                                    <Label check>
                                      <Input
                                        type="radio"
                                        id="Higher"
                                        name="Select Higher"
                                        value="Higher"
                                        checked={feeCheckBox == "higher"}
                                        onChange={(e) => {
                                          setFeeCheckBox("higher")
                                        }}
                                      />
                                      <span className="form-check-sign" />
                                      Select Higher
                                    </Label>
                                  </FormGroup>

                                  <FormGroup
                                    check
                                    className="form-check-radio d-inline mr-3"
                                  >
                                    <Label check>
                                      <Input
                                        id="lower"
                                        name="Select Lower"
                                        value="lower"
                                        checked={feeCheckBox == "lower"}
                                        onChange={(e) => {
                                          setFeeCheckBox("lower");
                                        }}
                                        type="radio"
                                      />
                                      <span className="form-check-sign" />
                                      Select Lower
                                    </Label>
                                  </FormGroup>
                                  <br />
                                </form>
                      </div>}
                    </div>

                  </Col>



                </Row>

                <div className="row">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                      onClick={() => {
                        addFeeSlab();
                      }}
                      disabled={Boolean(disableAddBtn)}
                    >
                      {disableAddBtn ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Add</span>
                      )}
                    </button>
                  </div>
                </div>

              </div>
            }
          </Modal>
        );
      default:
        return '';
    }
  };


  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeSecurityValue = (value) => {
    let grossAmount = parseFloat(value) * parseFloat(fundInvestmentSecurityEtity['price']);
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      total_amount: grossAmount.toFixed(2),
    }));
  };

  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeRate = (value) => {
    let grossAmount = parseFloat(fundInvestmentSecurityEtity['units']) * parseFloat(value);
    setFundInvestmentSecurityEtity((prevState) => ({
      ...fundInvestmentSecurityEtity,
      total_amount: grossAmount.toFixed(2),
    }));
  };

  //render dropdown for Fund Securities
  const renderFundSecuritiesDropdown = () => {
    return allFundSecurities.map((item: any, index: string) => {
      if (code !== item.code) {
        return (
          <option
            key={index}
            value={item.code}
          >{`${item.code} - ${item.name}`}</option>
        );
      }
    });
  };

  const [fundInvestmentSecurityEtity, setFundInvestmentSecurityEtity] = useState<any>({
    code: '',
    units: '',
    price: '',
    total_amount: '',
  });
  const [fundSecurities, setFundSecurities] = useState<any>([]);

  // add Fund Security Entity
  const addFundInvestmentSecurityInArray = () => {
    let count = 0;
    for (let i in fundInvestmentSecurityEtity) {
      if (fundInvestmentSecurityEtity[i] === '') {
        count = count + 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
    } else {
      const check = fundSecurities.filter((item) => {
        return item.code === fundInvestmentSecurityEtity['code'];
      });
      if (check.length > 0) {
        fundSecurities.map((item, index) => {
          if (item.code === fundInvestmentSecurityEtity['code']) {
            if (item.code === fundInvestmentSecurityEtity['code']) {
              item.units = isNumber(parseFloat(fundInvestmentSecurityEtity['units']));
              item.total_amount = isNumber(parseFloat(item.total_amount)) + isNumber(parseFloat(fundInvestmentSecurityEtity['total_amount']));
              item.price = isNumber(parseFloat(fundInvestmentSecurityEtity['price']));
            }
          }
        });
      } else {
        fundSecurities.push(fundInvestmentSecurityEtity);
        setFundSecurities(fundSecurities);
      }
      setViewAddFundInvestmentModal(false);
      setFundInvestmentSecurityEtity((prevState) => ({
        ...fundInvestmentSecurityEtity,
        code: '',
        units: '',
        price: '',
        total_amount: ''
      }));
      // let gross = 0, net = 0;
      // securites.map((item, index) => {
      //   net = net + parseFloat(item.net_amount);
      //   gross = gross + parseFloat(item.gross_amount);
      // });
      // if (net < 0) {
      //   let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
      //   setEquityInvestmentStatus(status);
      //   setFields((prevState) => ({
      //     ...fields,
      //     payment_type: 'Receipt',
      //     gross_amount: (gross * -1).toFixed(2),
      //     net_amount: (net * -1).toFixed(2),
      //   }));
      // } else {
      //   let status: any = net < 0 ? 'Credit' : 'Debit';
      //   setEquityInvestmentStatus(status);
      //   setFields((prevState) => ({
      //     ...fields,
      //     payment_type: 'Payment',
      //     gross_amount: gross.toFixed(2),
      //     net_amount: net.toFixed(2),
      //   }));
      // }
    }
  };

  // const [equityModalFlag, setEquityModalFlag] = useState(false);
  // const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);

  // ADD  Investment In Fund
  const [viewAddFundInvestmentModal, setViewAddFundInvestmentModal] = useState(false);
  const renderModalInvestmentInFundPopup = () => {
    switch (viewAddFundInvestmentModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddFundInvestmentModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> Add Fund Security </h6>
            </div>
            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col md='6'>
                    <div className="form-group">
                      Funds*
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          fundInvestmentSecurityEtity['code'] = e.target.value;
                          setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          // let value = allFundSecurities.filter((item: any) => {
                          //   return item.code === e.target.value;
                          // });

                          // fundInvestmentSecurityEtity['avg_rate'] = value[0]?.price || '0';
                          // setCloseRate(value[0]?.price || '0');
                          // fundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          // setAccTiltleName(e.target.value);

                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select
                        </option>
                        {renderFundSecuritiesDropdown()}
                      </select>

                    </div>
                  </Col>
                  <Col md='6'>
                    <div className="form-group">
                      NAV Price*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={
                          fundInvestmentSecurityEtity['price']
                        }
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          fundInvestmentSecurityEtity['price'] = value;
                          let grossAmount = parseFloat(fundInvestmentSecurityEtity['units'] || '0') * parseFloat(fundInvestmentSecurityEtity['price'] || '0');
                          fundInvestmentSecurityEtity['total_amount'] = grossAmount;
                          // fundInvestmentSecurityEtity['avg_rate'] = value
                          // setCloseRate(value);
                          setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          onChangeRate(value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <div className="form-group">
                      No of Units*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={
                          decimalValue(fundInvestmentSecurityEtity['units'], 4)
                        }
                        onValueChange={(e) => {
                          let value = e.value;
                          fundInvestmentSecurityEtity['units'] = value;
                          let grossAmount = parseFloat(fundInvestmentSecurityEtity['units'] || '0') * parseFloat(fundInvestmentSecurityEtity['price'] || '0');
                          fundInvestmentSecurityEtity['total_amount'] = grossAmount;
                          setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          onChangeSecurityValue(value);
                        }}
                      />

                    </div>
                  </Col>
                  <Col md='6'>
                    <div className="form-group">
                      Gross Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={
                          decimalValue(fundInvestmentSecurityEtity['total_amount'], 2)
                        }
                        onValueChange={(e) => {
                          let value = e.value;
                          fundInvestmentSecurityEtity['total_amount'] = value;
                          setFundInvestmentSecurityEtity(fundInvestmentSecurityEtity);
                          // onChangeGrossValue(value);
                        }}
                      // readOnly
                      />

                    </div>
                  </Col>
                </Row>


                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      if (parseFloat(fundInvestmentSecurityEtity['price']) <= 0) {
                        toast.error('Nav price must be greater or equal to zero')
                      } else if (parseFloat(fundInvestmentSecurityEtity['units']) <= 0) {
                        toast.error('No of Units must be greater than zero.')
                      } else {
                        addFundInvestmentSecurityInArray();
                      }
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal >
        );
      default:
        return '';
    }
  };

  //for stock Market

  const [equityModalFlag, setEquityModalFlag] = useState(false);
  const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const [stockEntity, setStockEntity] = useState<any>({
    code: '',
    unit: '',
    amount: '0',
    close_rate: '0',
    pledged: '0'
  });
  // ADD TAX Entity inside tax Array
  const addEquityStock = async () => {
    setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in stockEntity) {
      if (stockEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      setDisableEquityBtn(false);
    } else {
      setEquityModalFlag(false);
      const check = stockMarket.filter((item) => {
        return item.code === stockEntity['code'];
      });
      if (check.length > 0) {
        stockMarket.map((item, index) => {
          if (item.code === stockEntity['code']) {
            item.unit = (+item.unit + +stockEntity['unit']).toString();
            item.amount = (parseFloat(item.unit) * parseFloat(item.close_rate)).toFixed(2);
          }
        });
      } else {
        stockMarket.push(stockEntity);
        setStockMarket(stockMarket);
      }
      const filedEntirty = {
        code: '',
        unit: '',
        amount: '0',
        close_rate: '0',
        pledged: '0'
      };
      setStockEntity(filedEntirty);
      setDisableEquityBtn(false);
    }
  };

  //render fund Security table data
  const renderFundSecuirtiesTable = () => {
    return fundSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(items.price)).toFixed(4))}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(items.units)).toFixed(2))}</td>
          <td className='text-right'>{numberWithCommas(parseFloat(isNumber(items.total_amount)).toFixed(2))}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td className='text-right'>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...fundSecurities];
                array.splice(index, 1);
                setFundSecurities(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  //render equity table data
  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(4))}</td>
          <td>{numberWithCommas(items.unit || '') && numberWithCommas(items.unit || '')}</td>
          <td>{numberWithCommas((items.amount).replaceAll(',', '') || '') && numberWithCommas(parseFloat(items.amount.replaceAll(',', '')).toFixed(2))}</td>
          <td>{items.market}</td>
          <td>{numberWithCommas(items.pledged)}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td   >
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...stockMarket];
                array.splice(index, 1);
                setStockMarket(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  // render Model for adding Equity
  const renderEquityPopup = () => {
    switch (equityModalFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setEquityModalFlag(false);
            }}
          >

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setEquityModalFlag(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
            </div>


            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      Market*
                      <select
                        className="form-control w-100"
                        value={stockEntity.market}
                        onChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            market: e.target.value
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>Select Market</option>
                        <option value="REG">Regular</option>
                        <option value="ODL">Odd Lot</option>
                        <option value="FUT">Future</option>
                        <option value="BNB">Bills and Bonds</option>
                        <option value="IPO">Initial Public Offering</option>
                        <option value="BKB">Book Building</option>
                        <option value="CSF">Cash Settled Futures</option>
                        <option value="DSF">Direct Settled Futures</option>
                        <option value="SIF">Stock Index Futures</option>
                        <option value="NDM">Negotiable Deal Market</option>
                        <option value="FRO">Future Rollover Contract</option>
                        <option value="MTS">Margin Trading System</option>
                        <option value="SQR">Square Up</option>
                      </select>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      Select Security*
                      <select
                        className="form-control w-100"
                        value={stockEntity['code']}
                        onChange={(e) => {
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          setStockEntity({
                            ...stockEntity,
                            code: e.target.value,
                            close_rate: value[0].close_rate,
                            amount: (
                              parseFloat(e.target.value) *
                              parseFloat(stockEntity.Unit)
                            ).toFixed(2),
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {renderPsxDataDropdown()}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      Average Rate
                      <input
                        type="number"
                        className="form-control w-100 disable-input"
                        value={parseFloat(stockEntity['close_rate']).toFixed(4)}
                        readOnly
                      />
                    </div>

                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      Units*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={decimalValue(stockEntity['unit'], 4)}
                        onValueChange={(e) => {
                          let value = e.value
                          setStockEntity({
                            ...stockEntity,
                            unit: value,
                            amount: (
                              parseFloat(value) *
                              parseFloat(stockEntity.close_rate)
                            ).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['amount']}
                        readOnly
                      />
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="form-group">
                      Pledged
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['pledged']}
                        onValueChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            pledged: e.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>


                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      if (parseFloat(stockEntity['unit']) <= 0) {
                        toast.error('Units must be greater than zero.')
                      } else {
                        addEquityStock();
                      }
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code: '',
    name: '',
    principal_amount: '',
    interest_rate: '',
    from_date: '',
    to_date: '',
    bank: '',
    branch: '',
  });
  const addTermDeposit = async () => {
    // setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in termDepositEntity) {
      if (termDepositEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      // setDisableEquityBtn(false);
    } else {
      setTermDepositModal(false);
      // const check = termDeposit.filter((item) => {
      //   return item.interest_rate === termDepositEntity['interest_rate'];
      // });
      // if (check.length > 0) {
      //   termDeposit.map((item, index) => {
      //     if (item.code === termDepositEntity['code']) {
      //       item.interest_rate = (+item.interest_rate + +termDepositEntity['interest_rate']).toString();
      //     }
      //   });
      // } else {
      termDeposit.push(termDepositEntity);
      setTermDeposit(termDeposit);
      // }
      const filedEntirty = {
        code: '',
        name: '',
        principal_amount: '',
        interest_rate: '',
        from_date: '',
        to_date: '',
        bank: '',
        branch: '',
      };
      setTermDepositEntity(filedEntirty);
      // setDisableEquityBtn(false);
    }
  }

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount)}</td>
          <td>{numberWithCommas(items.interest_rate)}</td>
          <td>{items.from_date}</td>
          <td>{items.to_date}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...termDeposit];
                array.splice(index, 1);
                setTermDeposit(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTermDepositPopup = () => {
    switch (termDepositModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            enforceFocus={false}
            onHide={() => {
              setTermDepositModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTermDepositModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Term Deposit</h6>
            </div>


            <div className="modal-body ">
              <div className="">

                <Row>
                  <Col>
                    <div className="form-group">
                      Type
                      <select className='form-control w100'
                        onChange={(e) => {
                          termDepositEntity['code'] = e.target.value;
                          termDepositEntity['name'] = e.nativeEvent.target === null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                          setTermDepositEntity(termDepositEntity);
                        }}
                      >
                        <option value='' hidden>Select Type</option>
                        <option value='TDR'>Term Deposit Receipts</option>
                        <option value='CDR'>Call Deposit Receipts</option>
                        <option value='LOP'>Letter of Placement</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Principal Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        // value={stockEntity['close_rate']}
                        onValueChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            principal_amount: e.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      Interest Rate
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        onValueChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            interest_rate: e.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      From Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            from_date: e.target.value
                          });
                        }}
                      />
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      To Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            to_date: e.target.value
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      Bank
                      <select className="form-control w-100"
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            bank: e.target.value
                          });
                          getCounterBranchByName(e.target.value);
                        }}>

                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankDropdown()}
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Branch
                      <select className="form-control w-100"
                        onChange={(e) => {
                          setTermDepositEntity({
                            ...termDepositEntity,
                            branch: e.target.value
                          });
                        }}>

                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Branch
                        </option>
                        {renderBranchDropdown()}
                      </select>
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addTermDeposit();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>

      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/funds')
                  }}></i>Setup - Funds</h4>
                <Link to="/admin/funds" className="btn btn-primary btn-sm" replace>
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Fund Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>
                          AMC Name <span className="color-amber">*</span>
                        </label>
                        <div  >
                          <select
                            className={`form-control  w-100 ${amcNameError ? 'required-border' : ''
                              }`}
                            value={amcName}
                            onChange={(e) => {
                              setAmcError('');
                              setAmcName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select An AMC
                            </option>
                            {renderAmcDropdown()}
                          </select>
                          {/* {amcNameError ? <p className="error-labels error-message2">{amcNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>
                          Fund Name <span className="color-amber">*</span>
                        </label>
                        <div  >
                          <input
                            type="text"
                            className={`form-control  w-100 ${namelError ? 'required-border' : ''
                              }`}
                            value={fundName}
                            onChange={(e) => {
                              setFundName(e.target.value);
                              setNameError('');
                            }}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                AddFunds();
                              }
                            }}
                          />
                          {/* {namelError ? <p className="error-labels error-message2">{namelError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>
                          Fund Code <span className="color-amber">*</span>
                        </label>
                        <div>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${codeError ? 'required-border' : ''
                                }`}
                              value={code}
                              onChange={(e) => {
                                // var cleaned = e.target.value.replace(/\d/, '');
                                // if (cleaned.length == 5 && !cleaned.includes('-')) {
                                //   // cleaned = cleaned + '-';
                                // } else if (cleaned.length > 10) {
                                //   cleaned = cleaned.substring(0, 10);
                                // }
                                // changeCodeValue(cleaned.toUpperCase());
                                let value = e.target.value.toUpperCase();
                                if ((value.length <= 12 && value.match(/^[A-Z-]+$/)) || value === '') {
                                  setCode(value);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  AddFunds();
                                }
                              }}
                            />
                          </div>
                          <div className="d-flex">
                            {/* <p style={{ fontSize: '11px' }}>
                          Code Pattern (AAAAA-AAAAA)
                        </p> */}
                            {codeError ? (
                              <span
                                className="ml-auto"
                                style={{ fontSize: '11px' }}
                              >
                                {codeError}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>
                          Nature / Type of Fund
                          <span className="color-amber">*</span>
                        </label>
                        <div  >
                          <select
                            className={`form-control input-broker  w-100 ${fundTypeError ? 'required-border' : ''
                              }`}
                            value={fundType}
                            onChange={(e) => {
                              setFundType(e.target.value);
                              setFundTypeError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select
                            </option>
                            <option value="Agressive Income Fund">
                              Agressive Income Fund
                            </option>
                            <option value="Asset Allocation Fund">
                              Asset Allocation Fund
                            </option>
                            <option value="Income Fund">Income Fund</option>
                            <option value="Money Market Fund">
                              Money Market Fund
                            </option>
                            <option value="Stock Fund">Stock Fund</option>
                            <option value="Private Fund Managment Co">
                              Private Fund Managment Co
                            </option>
                            <option value="Fund of Fund">Fund of Fund</option>
                            <option value="Special Managed Account">Special Managed Account (SMA)</option>
                            <option value="Private Equity">Private Equity</option>
                            <option value="Venture Capital">Venture Capital</option>
                            <option value="Fixed Return Fund">Fixed Return Fund</option>
                            <option value="REIT Scheme">REIT Scheme</option>
                            <option value="Live Stock">Live Stock</option>
                          </select>
                          {/* {fundTypeError ? <p className="error-labels error-message">{fundTypeError}</p> : ''} */}
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Incorporation
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Date of Incorporation</label>
                        <div  >
                          <input
                            type="date"
                            className={`form-control  w-100 ${incorporationDateError ? 'required-border' : ''
                              }`}
                            value={incorporationDate}
                            onChange={(e) => {
                              setIncorporationDate(e.target.value);
                              setIncorporationDateError('');
                            }}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                AddFunds();
                              }
                            }}
                          />
                          {/* {incorporationDateError ? <p className="error-labels error-message2">{incorporationDateError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Date of Revocation</label>
                        <div  >
                          <input
                            type="date"
                            className="form-control"
                            value={revocationDate}
                            onChange={(e) => {
                              setRevocationDate(e.target.value);
                            }}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                AddFunds();
                              }
                            }}
                          />
                        </div>
                      </div>


                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>
                              NAV <span className="color-amber">*</span>
                            </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 ${navError ? 'required-border' : ''
                                  }`}
                                value={decimalValue(nav, 4)}
                                onChange={(e) => {
                                  let value = decimalValue(e.target.value, 4);
                                  setNav(value);
                                  setCodeChange(value);
                                  setNavError('');
                                }}
                                onKeyPress={(event) => {
                                  if (event.key === 'Enter') {
                                    AddFunds();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">Face Value <span className="color-amber">*</span></label>
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              className={`form-control  w-100 text-right ${faceValError ? 'required-border' : ''
                                }`}
                              value={decimalValue(faceValue, 2)}
                              onValueChange={(e) => {
                                setFaceValue(e.value);
                                setFaceValError('');
                              }}
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  AddFunds();
                                }
                              }} />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>
                              CDC Participant ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={cdc_participant_id}
                                onChange={(e) => {
                                  setCDCParticipantID(e.target.value);
                                }}

                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">CDC Account Number </label>
                            <input type="text" className="form-control"
                              value={cdc_account_number}
                              onChange={(e) => {
                                setCDCAccountNumber(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>
                              BATS ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className="form-control  w-100"
                                value={bats_id}
                                onChange={(e) => {
                                  setBatsID(e.target.value);
                                  // setBatsError('')
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label>
                              Clearing Member ID
                            </label>
                            <div  >
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={clearMember_id}
                                onChange={(e) => {
                                  setCleariMemberID(e.target.value);
                                  // setClearMemberError('')
                                }}

                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className=" ">
                            <label>PSX Listing</label>
                            <div>
                              <form action="">

                                <FormGroup check className="form-check-radio d-inline mr-3">
                                  <Label check>
                                    <Input
                                      type="radio"
                                      id="listed"
                                      name="listed"
                                      value="true"
                                      checked={isCheckBoxSelected}
                                      onChange={(e) => {
                                        setPsxListingError('');
                                        setPsxListing(e.target.value);
                                        setCheckBoxSelected(true);
                                      }}

                                    />
                                    <span className="form-check-sign" />
                                    Listed
                                  </Label>
                                </FormGroup>

                                <FormGroup check className="form-check-radio d-inline">
                                  <Label check>
                                    <Input
                                      type="radio"
                                      id="unlisted"
                                      name="listed"
                                      value="false"
                                      onChange={(e) => {
                                        setPsxListingError('');
                                        setPsxListing(e.target.value);
                                        setCheckBoxSelected(false);
                                      }}
                                    />
                                    <span className="form-check-sign" />
                                    Unlisted
                                  </Label>
                                </FormGroup>

                              </form>
                              {psxListingError ? (
                                <p className="error-labels error-mes">
                                  {psxListingError}
                                </p>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Fee Slab
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="input-size-pos">

                        <Row>
                          <Col>
                            <label>Add Fee Slab</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              <button
                                data-tip="Add Fee Slab"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setModalView(!modalView);
                                  setFeeSlabFrom('')
                                  setFeeSlabTo('')
                                  setFeeRate('')
                                  setFeeFixAmount('')
                                  setFeeSlabFromErr('')
                                  setFeeComparingRate("")
                                  setFeeCheckBox("")
                                  setFeeVariableRate("")
                                  setFeeSlabToErr('')
                                  setFeeRateErr('')
                                  setFeeFixAmountErr('')
                                  setDisableAddBtn(false);
                                  setStockModal(false);
                                  setFeeSlabModal(true)
                                }}
                              > <i className="fa fa-plus"></i> </button>
                            </div>
                          </Col>


                        </Row>
                      </div>

                      <div className="table-responsive">
                        {allfeeSlab.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2">
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Slab From</th>
                                  <th>Slab To</th>
                                  <th className='text-right'>Fixed Rate %</th>
                                  <th className='text-right'>Fix Amount</th>
                                  <th className="text-right">Variable Rate %</th>
                                  <th className="text-right">Comparing Rate %</th>
                                  <th >Select Amount</th>
                                  <th className='text-right'>Action</th>


                                </tr>
                              </thead>
                              <tbody>{renderFeeDataInTable()}</tbody>
                            </table>
                          </div>
                        )}

                      </div>


                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Money Market Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="input-size-pos">

                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              <button
                                data-tip="Add Money Maket"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setModalView(!modalView);
                                  setDisableAddBtn(false);
                                  setStockModal(false);
                                }}
                              > <i className="fa fa-plus"></i> </button>
                            </div>
                          </Col>
                          <Col>
                            <label>Upload Portfolio</label>
                            <div onClick={() => myRef1?.current?.click()}>
                              <div className="form-group">
                                <div className="form-control" data-tip="Choose File">

                                  {fileName === '' ? 'Choose File' : fileName}
                                </div>
                                <input
                                  type="file"
                                  ref={myRef1}
                                  style={{ display: 'none' }}
                                  multiple={false}
                                  data-tip="Choose File"
                                  onChange={(e) => {
                                    uploadMoneyMarketPortfolio(e);
                                  }}
                                  onClick={(e) => {
                                    e.currentTarget.value = '';
                                  }}
                                />
                                {fileError ? (
                                  <p className="error-labels error-message2">{fileError}</p>
                                ) : (
                                  ''
                                )}

                              </div>
                            </div>
                            {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i>}
                          </Col>

                        </Row>
                      </div>

                      <div className="table-responsive">
                        {moneyMarket.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2">
                            {/* <h3>Money Market Portfolio</h3> */}
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Security Type</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th>Coupon Rate</th>
                                  <th>Yield</th>
                                  <th>Money Market Face Value</th>
                                  <th>Total Face Value</th>
                                  <th>Price</th>
                                  <th>Units</th>
                                  <th>Settlement Amount</th>
                                  <th>Tenor</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundDataInTable()}</tbody>
                            </table>
                          </div>
                        )}

                      </div>


                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Stock Market Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="input-size-pos ml-3">
                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />

                              <button
                                data-tip="Add Stock Market"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setEquityModalFlag(!equityModalFlag);
                                  setDisableEquityBtn(false);
                                  setStockModal(true);
                                  const filedEntirty = {
                                    code: '',
                                    unit: '',
                                    market: '',
                                    amount: '0',
                                    close_rate: '0',
                                  };
                                  setStockEntity(filedEntirty);
                                }}
                              > <i className="fa fa-plus"> </i> </button>
                            </div>
                          </Col>
                          <Col>
                            <label>Upload Portfolio</label>
                            <div onClick={() => myRef2?.current?.click()}>
                              <div className="form-group">
                                <div className="form-control" data-tip="Choose File">

                                  {fileName2 === '' ? 'Choose File' : fileName2}
                                </div>
                                <input
                                  type="file"
                                  ref={myRef2}
                                  style={{ display: 'none' }}
                                  multiple={false}
                                  data-tip="Choose File"
                                  onChange={(e) => {
                                    uploadStockMarketPortfolio(e);
                                  }}
                                  onClick={(e) => {
                                    e.currentTarget.value = '';
                                  }}
                                />
                                {fileError2 ? (
                                  <p className="error-labels error-message2">{fileError2}</p>
                                ) : (
                                  ''
                                )}

                              </div>
                            </div>
                            {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i>}
                          </Col>
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {stockMarket.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2 ">
                            {/* <h3>Stock Market Portfolio</h3> */}
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Symbol / Code</th>
                                  <th>Average Rate</th>
                                  <th>Quantity</th>
                                  <th>Amount</th>
                                  <th>Market</th>
                                  <th>Pledged</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderEquityInTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Mutual Funds Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="input-size-pos ml-3">
                        <Row>
                          <Col>
                            <label>Add Portfolio</label>
                            <div>
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />

                              <button
                                data-tip="Add Stock Market"
                                className="btn-round btn-icon btn btn-primary"
                                onClick={() => {
                                  setViewAddFundInvestmentModal(true)
                                  // setEquityModalFlag(!equityModalFlag);
                                  setDisableEquityBtn(false);
                                  setStockModal(true);
                                  const investmentSecurity = {
                                    code: '',
                                    units: '',
                                    price: '',
                                    total_amount: '',
                                  };
                                  setFundInvestmentSecurityEtity(investmentSecurity);
                                }}
                              > <i className="fa fa-plus"> </i> </button>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div className="table-responsive">
                        {fundSecurities.length > 0 && (
                          <div className="input-size-pos mb-3 mt-2 ">
                            {/* <h3>Stock Market Portfolio</h3> */}
                            <table className="table   ">
                              <thead>
                                <tr>
                                  <th>Fund Symbol / Code</th>
                                  <th className='text-right'>NAV Price</th>
                                  <th className='text-right'>No of Units</th>
                                  <th className='text-right'>Gross Amount</th>
                                  <th className='text-right'>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundSecuirtiesTable()}</tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={AddFunds}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </div>

              {renderModalPopup()}
              {renderEquityPopup()}
              {renderTermDepositPopup()}
              {renderModalInvestmentInFundPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SetupFunds;